import React from 'react'
import AuthUser from './AuthUser'
import { useState } from 'react'

function Login() {
  const { http, setToken } = AuthUser();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const SubmitFrom = () => {
    // API Call//
    http.post('http://127.0.0.1:8000/api/Login', {email:email, password: password }).then((res) => {
      setToken(res.data.user, res.data.access_token);
      // console.log(res.data);
    })
  }

  return (
    <>
   
      {/* content begin */}
      <div className="no-bottom no-top" id="content">
        <div id="top" />
        <section id="section-hero" aria-label="section" className="jarallax">
          <img src="images/background/2.jpg" className="jarallax-img" alt="" />
          <div className="v-center">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-4 offset-lg-4">
                  <div
                    className="padding40 rounded-3 shadow-soft"
                    data-bgcolor="#ffffff"
                  >
                    <h4>Login</h4>
                    <div className="spacer-10" />
                    <form
                      id="form_register"
                      className="form-border"
                      //   method="post"
                      action=""
                    >
                      <div className="field-set">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="form-control"
                          placeholder="Email"
                          onChange={e => setEmail(e.target.value)}
                        />
                      </div><br />
                      <div className="field-set">
                        <input
                          type="password"
                          name="password"
                          id="password"
                          className="form-control"
                          placeholder="Password"
                          onChange={e => setPassword(e.target.value)}
                        />
                      </div>
                      <div id="submit">
                        <input
                          type="button"
                          id="send_message"
                          defaultValue="Sign In"
                          className="btn-main btn-fullwidth rounded-3"
                          onClick={SubmitFrom}
                        />
                      </div>
                    </form>
                    <div className="title-line">Or&nbsp;sign&nbsp;up&nbsp;with</div>
                    <div className="row g-2">
                      <div className="col-lg-6">
                        <a className="btn-sc btn-fullwidth mb10" href="#">
                          <img src="images/svg/google_icon.svg" alt="" />
                          Google
                        </a>
                      </div>
                      <div className="col-lg-6">
                        <a className="btn-sc btn-fullwidth mb10" href="#">
                          <img src="images/svg/facebook_icon.svg" alt="" />
                          Facebook
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
  
      {/* content close */}
    </>

  )
}

export default Login
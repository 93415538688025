import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function Counters() {
    return (
        <>
        <Header/>
            {/* content begin */}
            <div className="no-bottom no-top" id="content">
                <div id="top" />
                {/* section begin */}
                <section id="subheader" className="jarallax text-light">
                    <img
                        src="images/background/subheader.jpg"
                        className="jarallax-img"
                        alt=""
                    />
                    <div className="center-y relative text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h1>Counters</h1>
                                </div>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* section close */}
                <section>
                    <div className="container">
                        <div className="row g-custom-x force-text-center">
                            <div className="col-md-3 col-sm-6 mb-sm-30">
                                <div className="de_count wow fadeInUp">
                                    <h3 className="timer" data-to={15425} data-speed={3000}>
                                        0
                                    </h3>
                                    Completed Orders
                                    <p className="d-small">
                                        Lorem ipsum adipisicing officia in adipisicing do velit sit
                                        tempor ea consectetur.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 mb-sm-30">
                                <div className="de_count wow fadeInUp">
                                    <h3 className="timer" data-to={8745} data-speed={3000}>
                                        0
                                    </h3>
                                    Happy Customers
                                    <p className="d-small">
                                        Lorem ipsum adipisicing officia in adipisicing do velit sit
                                        tempor ea consectetur.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 mb-sm-30">
                                <div className="de_count wow fadeInUp">
                                    <h3 className="timer" data-to={235} data-speed={3000}>
                                        0
                                    </h3>
                                    Vehicles Fleet
                                    <p className="d-small">
                                        Lorem ipsum adipisicing officia in adipisicing do velit sit
                                        tempor ea consectetur.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 mb-sm-30">
                                <div className="de_count wow fadeInUp">
                                    <h3 className="timer" data-to={15} data-speed={3000}>
                                        0
                                    </h3>
                                    Years Experience
                                    <p className="d-small">
                                        Lorem ipsum adipisicing officia in adipisicing do velit sit
                                        tempor ea consectetur.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="no-top no-bottom" data-bgcolor="#f2f2f2">
                    <div className="container">
                        <div className="row force-text-center">
                            <div className="col-md-3 col-sm-6 mb-sm-30">
                                <div className="de_count no-shadow no-bg wow fadeInUp">
                                    <h3 className="timer" data-to={15425} data-speed={3000}>
                                        0
                                    </h3>
                                    Completed Orders
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 mb-sm-30">
                                <div className="de_count no-shadow no-bg wow fadeInUp">
                                    <h3 className="timer" data-to={8745} data-speed={3000}>
                                        0
                                    </h3>
                                    Happy Customers
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 mb-sm-30">
                                <div className="de_count no-shadow no-bg wow fadeInUp">
                                    <h3 className="timer" data-to={235} data-speed={3000}>
                                        0
                                    </h3>
                                    Vehicles Fleet
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 mb-sm-30">
                                <div className="de_count no-shadow no-bg wow fadeInUp">
                                    <h3 className="timer" data-to={15} data-speed={3000}>
                                        0
                                    </h3>
                                    Years Experience
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* content close */}
            <Footer/>
        </>

    )
}

export default Counters
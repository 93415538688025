import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function DarkHome1() {
  return (
    <>
    <Header/>
  {/* content begin */}
  <div className="no-bottom no-top" id="content">
    <div id="top" />
    <section
      id="de-carousel"
      className="no-top no-bottom carousel slide carousel-fade"
      data-mdb-ride="carousel"
    >
      {/* Indicators */}
      <ol className="carousel-indicators z1000">
        <li
          data-mdb-target="#de-carousel"
          data-mdb-slide-to={0}
          className="active"
        />
        <li data-mdb-target="#de-carousel" data-mdb-slide-to={1} />
        <li data-mdb-target="#de-carousel" data-mdb-slide-to={2} />
      </ol>
      {/* Inner */}
      <div className="carousel-inner position-relative">
        {/* Single item */}
        <div className="carousel-item active jarallax">
          <img src="images/slider/1.jpg" className="jarallax-img" alt="" />
          <div className="mask">
            <div className="no-top no-bottom">
              <div className="h-100 v-center">
                <div className="container">
                  <div className="row gx-5 align-items-center">
                    <div className="col-lg-6 offset-lg-3 text-center mb-sm-30">
                      <h1 className="s3 mb-3 wow fadeInUp">Premium Cars</h1>
                      <p className="lead wow fadeInUp" data-wow-delay=".3s">
                        Discover the world in unparalleled comfort and style.
                      </p>
                      <div className="spacer-10" />
                      <a
                        className="btn-line mb10 wow fadeInUp"
                        data-wow-delay=".6s"
                        href="#"
                      >
                        Discover
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Single item */}
        <div className="carousel-item jarallax">
          <img src="images/slider/2.jpg" className="jarallax-img" alt="" />
          <div className="mask">
            <div className="no-top no-bottom">
              <div className="h-100 v-center">
                <div className="container">
                  <div className="row gx-5 align-items-center">
                    <div className="col-lg-6 offset-lg-3 text-center mb-sm-30">
                      <h1 className="s3 mb-3 wow fadeInUp">First Class</h1>
                      <p className="lead wow fadeInUp" data-wow-delay=".3s">
                        Creating unforgettable moments and exceeding your every
                        expectation.
                      </p>
                      <div className="spacer-10" />
                      <a
                        className="btn-line mb10 wow fadeInUp"
                        data-wow-delay=".6s"
                        href="#"
                      >
                        Discover
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Single item */}
        <div className="carousel-item jarallax">
          <img src="images/slider/3.jpg" className="jarallax-img" alt="" />
          <div className="mask">
            <div className="no-top no-bottom">
              <div className="h-100 v-center">
                <div className="container">
                  <div className="row gx-5 align-items-center">
                    <div className="col-lg-6 offset-lg-3 text-center mb-sm-30">
                      <h1 className="s3 mb-3 wow fadeInUp">Save Drivers</h1>
                      <p className="lead wow fadeInUp" data-wow-delay=".3s">
                        Our experienced drivers are ready to accompany your
                        journey.
                      </p>
                      <div className="spacer-10" />
                      <a
                        className="btn-line mb10 wow fadeInUp"
                        data-wow-delay=".6s"
                        href="#"
                      >
                        Discover
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Inner */}
      {/* Controls */}
      <a
        className="carousel-control-prev"
        href="#de-carousel"
        role="button"
        data-mdb-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="sr-only">Previous</span>
      </a>
      <a
        className="carousel-control-next"
        href="#de-carousel"
        role="button"
        data-mdb-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="sr-only">Next</span>
      </a>
      <div className="de-gradient-edge-bottom" />
    </section>
    <section id="section-cars">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 offset-lg-3 text-center">
            <h2>Our Vehicle Fleet</h2>
            <p>
              Driving your dreams to reality with an exquisite fleet of
              versatile vehicles for unforgettable journeys.
            </p>
            <div className="spacer-20" />
          </div>
          <div id="items-carousel" className="owl-carousel wow fadeIn">
            <div className="col-lg-12">
              <div className="de-item mb30">
                <div className="d-img">
                  <img
                    src="images/cars/jeep-renegade.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="d-info">
                  <div className="d-text">
                    <h4>Jeep Renegade</h4>
                    <div className="d-item_like">
                      <i className="fa fa-heart" />
                      <span>74</span>
                    </div>
                    <div className="d-atr-group">
                      <span className="d-atr">
                        <img src="images/icons/1-green.svg" alt="" />5
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/2-green.svg" alt="" />2
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/3-green.svg" alt="" />4
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/4-green.svg" alt="" />
                        SUV
                      </span>
                    </div>
                    <div className="d-price">
                      Daily rate from <span>$265</span>
                      <a className="btn-main" href="02_dark-car-single.html">
                        Rent Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="de-item mb30">
                <div className="d-img">
                  <img
                    src="images/cars/bmw-m5.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="d-info">
                  <div className="d-text">
                    <h4>BMW M2</h4>
                    <div className="d-item_like">
                      <i className="fa fa-heart" />
                      <span>36</span>
                    </div>
                    <div className="d-atr-group">
                      <span className="d-atr">
                        <img src="images/icons/1-green.svg" alt="" />5
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/2-green.svg" alt="" />2
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/3-green.svg" alt="" />4
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/4-green.svg" alt="" />
                        Sedan
                      </span>
                    </div>
                    <div className="d-price">
                      Daily rate from <span>$244</span>
                      <a className="btn-main" href="02_dark-car-single.html">
                        Rent Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="de-item mb30">
                <div className="d-img">
                  <img
                    src="images/cars/ferrari-enzo.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="d-info">
                  <div className="d-text">
                    <h4>Ferarri Enzo</h4>
                    <div className="d-item_like">
                      <i className="fa fa-heart" />
                      <span>85</span>
                    </div>
                    <div className="d-atr-group">
                      <span className="d-atr">
                        <img src="images/icons/1-green.svg" alt="" />5
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/2-green.svg" alt="" />2
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/3-green.svg" alt="" />4
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/4-green.svg" alt="" />
                        Exotic Car
                      </span>
                    </div>
                    <div className="d-price">
                      Daily rate from <span>$167</span>
                      <a className="btn-main" href="02_dark-car-single.html">
                        Rent Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="de-item mb30">
                <div className="d-img">
                  <img
                    src="images/cars/ford-raptor.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="d-info">
                  <div className="d-text">
                    <h4>Ford Raptor</h4>
                    <div className="d-item_like">
                      <i className="fa fa-heart" />
                      <span>59</span>
                    </div>
                    <div className="d-atr-group">
                      <span className="d-atr">
                        <img src="images/icons/1-green.svg" alt="" />5
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/2-green.svg" alt="" />2
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/3-green.svg" alt="" />4
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/4-green.svg" alt="" />
                        Truck
                      </span>
                    </div>
                    <div className="d-price">
                      Daily rate from <span>$147</span>
                      <a className="btn-main" href="02_dark-car-single.html">
                        Rent Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="de-item mb30">
                <div className="d-img">
                  <img
                    src="images/cars/mini-cooper.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="d-info">
                  <div className="d-text">
                    <h4>Mini Cooper</h4>
                    <div className="d-item_like">
                      <i className="fa fa-heart" />
                      <span>19</span>
                    </div>
                    <div className="d-atr-group">
                      <span className="d-atr">
                        <img src="images/icons/1-green.svg" alt="" />5
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/2-green.svg" alt="" />2
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/3-green.svg" alt="" />4
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/4-green.svg" alt="" />
                        Hatchback
                      </span>
                    </div>
                    <div className="d-price">
                      Daily rate from <span>$238</span>
                      <a className="btn-main" href="02_dark-car-single.html">
                        Rent Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="de-item mb30">
                <div className="d-img">
                  <img
                    src="images/cars/vw-polo.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="d-info">
                  <div className="d-text">
                    <h4>VW Polo</h4>
                    <div className="d-item_like">
                      <i className="fa fa-heart" />
                      <span>79</span>
                    </div>
                    <div className="d-atr-group">
                      <span className="d-atr">
                        <img src="images/icons/1-green.svg" alt="" />5
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/2-green.svg" alt="" />2
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/3-green.svg" alt="" />4
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/4-green.svg" alt="" />
                        Hatchback
                      </span>
                    </div>
                    <div className="d-price">
                      Daily rate from <span>$106</span>
                      <a className="btn-main" href="02_dark-car-single.html">
                        Rent Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="text-light jarallax">
      <img src="images/background/9.jpg" className="jarallax-img" alt="" />
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-6 wow fadeInRight">
            <h2>
              We offer customers a wide range of commercial cars and luxury cars
              for any occasion.
            </h2>
          </div>
          <div className="col-lg-6 wow fadeInLeft">
            At our car rental agency, we believe that everyone deserves to
            experience the pleasure of driving a reliable and comfortable
            vehicle, regardless of their budget. We have curated a diverse fleet
            of well-maintained cars, ranging from sleek sedans to spacious SUVs,
            all at competitive prices. With our streamlined rental process, you
            can quickly and conveniently reserve your desired vehicle. Whether
            you need transportation for a business trip, family vacation, or
            simply want to enjoy a weekend getaway, we have flexible rental
            options to accommodate your schedule.
          </div>
        </div>
        <div className="spacer-double" />
        <div className="row text-center">
          <div className="col-md-3 col-sm-6 mb-sm-30">
            <div className="de_count transparent text-light wow fadeInUp">
              <h3 className="timer" data-to={15425} data-speed={3000}>
                0
              </h3>
              Completed Orders
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-sm-30">
            <div className="de_count transparent text-light wow fadeInUp">
              <h3 className="timer" data-to={8745} data-speed={3000}>
                0
              </h3>
              Happy Customers
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-sm-30">
            <div className="de_count transparent text-light wow fadeInUp">
              <h3 className="timer" data-to={235} data-speed={3000}>
                0
              </h3>
              Vehicles Fleet
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-sm-30">
            <div className="de_count transparent text-light wow fadeInUp">
              <h3 className="timer" data-to={15} data-speed={3000}>
                0
              </h3>
              Years Experience
            </div>
          </div>
        </div>
      </div>
    </section>
    <section aria-label="section" className="no-bottom">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 offset-lg-3 text-center">
            <h2>Our Features</h2>
            <p>
              Discover a world of convenience, safety, and customization, paving
              the way for unforgettable adventures and seamless mobility
              solutions.
            </p>
            <div className="spacer-20" />
          </div>
          <div className="clearfix" />
          <div className="col-lg-3">
            <div
              className="box-icon s2 p-small mb20 wow fadeInRight"
              data-wow-delay=".5s"
            >
              <i className="fa bg-color fa-trophy" />
              <div className="d-inner">
                <h4>First class services</h4>
                Where luxury meets exceptional care, creating unforgettable
                moments and exceeding your every expectation.
              </div>
            </div>
            <div
              className="box-icon s2 p-small mb20 wow fadeInL fadeInRight"
              data-wow-delay=".75s"
            >
              <i className="fa bg-color fa-road" />
              <div className="d-inner">
                <h4>24/7 road assistance</h4>
                Reliable support when you need it most, keeping you on the move
                with confidence and peace of mind.
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <img
              src="images/misc/car-2.png"
              alt=""
              className="img-fluid wow fadeInUp"
            />
          </div>
          <div className="col-lg-3">
            <div
              className="box-icon s2 d-invert p-small mb20 wow fadeInL fadeInLeft"
              data-wow-delay="1s"
            >
              <i className="fa bg-color fa-tag" />
              <div className="d-inner">
                <h4>Quality at Minimum Expense</h4>
                Unlocking affordable brilliance with elevating quality while
                minimizing costs for maximum value.
              </div>
            </div>
            <div
              className="box-icon s2 d-invert p-small mb20 wow fadeInL fadeInLeft"
              data-wow-delay="1.25s"
            >
              <i className="fa bg-color fa-map-pin" />
              <div className="d-inner">
                <h4>Free Pick-Up &amp; Drop-Off</h4>
                Enjoy free pickup and drop-off services, adding an extra layer
                of ease to your car rental experience.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="section-testimonials" className="no-top no-bottom">
      <div className="container">
        <div className="row g-4 align-items-center">
          <div className="col-md-4">
            <div className="de-image-text">
              <div className="d-text">
                <div className="d-quote id-color">
                  <i className="fa fa-quote-right" />
                </div>
                <h4>Excellent Service! Car Rent Service!</h4>
                <blockquote>
                  I have been using Rentaly for my Car Rental needs for over 5
                  years now. I have never had any problems with their service.
                  Their customer support is always responsive and helpful. I
                  would recommend Rentaly to anyone looking for a reliable Car
                  Rental provider.
                  <span className="by">Stepanie Hutchkiss</span>
                </blockquote>
              </div>
              <img
                src="images/testimonial/1.jpg"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="spacer-double sm-hide" />
            <div className="spacer-double sm-hide" />
            <div className="de-image-text">
              <div className="d-text">
                <div className="d-quote id-color">
                  <i className="fa fa-quote-right" />
                </div>
                <h4>Excellent Service! Car Rent Service!</h4>
                <blockquote>
                  We have been using Rentaly for our trips needs for several
                  years now and have always been happy with their service. Their
                  customer support is Excellent Service! and they are always
                  available to help with any issues we have. Their prices are
                  also very competitive.
                  <span className="by">Jovan Reels</span>
                </blockquote>
              </div>
              <img
                src="images/testimonial/2.jpg"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="spacer-double sm-hide" />
            <div className="spacer-double sm-hide" />
            <div className="spacer-double sm-hide" />
            <div className="spacer-double sm-hide" />
            <div className="de-image-text">
              <div className="d-text">
                <div className="d-quote id-color">
                  <i className="fa fa-quote-right" />
                </div>
                <h4>Excellent Service! Car Rent Service!</h4>
                <blockquote>
                  Endorsed by industry experts, Rentaly is the Car Rental
                  solution you can trust. With years of experience in the field,
                  we provide fast, reliable and secure Car Rental services.
                  <span className="by">Kanesha Keyton</span>
                </blockquote>
              </div>
              <img
                src="images/testimonial/3.jpg"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="section-news">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 offset-lg-3 text-center">
            <h2>Latest News</h2>
            <p>
              Breaking news, fresh perspectives, and in-depth coverage - stay
              ahead with our latest news, insights, and analysis.
            </p>
            <div className="spacer-20" />
          </div>
          <div className="col-lg-4 mb10">
            <div className="bloglist s2 item">
              <div className="post-content">
                <div className="post-image">
                  <div className="date-box">
                    <div className="m">10</div>
                    <div className="d">MAR</div>
                  </div>
                  <img
                    alt=""
                    src="images/news/pic-blog-1.jpg"
                    className="lazy"
                  />
                </div>
                <div className="post-text">
                  <h4>
                    <a href="news-single.html">
                      Enjoy Best Travel Experience
                      <span />
                    </a>
                  </h4>
                  <p>
                    Traveling is an enriching experience that allows us to
                    explore new destinations, immerse ourselves in different
                    cultures, and create lifelong memories.
                  </p>
                  <a className="btn-line" href="#">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb10">
            <div className="bloglist s2 item">
              <div className="post-content">
                <div className="post-image">
                  <div className="date-box">
                    <div className="m">12</div>
                    <div className="d">MAR</div>
                  </div>
                  <img
                    alt=""
                    src="images/news/pic-blog-2.jpg"
                    className="lazy"
                  />
                </div>
                <div className="post-text">
                  <h4>
                    <a href="news-single.html">
                      The Future of Car Rent
                      <span />
                    </a>
                  </h4>
                  <p>
                    As technology continues to advance at a rapid pace, the car
                    rental industry is poised for a transformative shift. The
                    future of car rental promises..
                  </p>
                  <a className="btn-line" href="#">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb10">
            <div className="bloglist s2 item">
              <div className="post-content">
                <div className="post-image">
                  <div className="date-box">
                    <div className="m">14</div>
                    <div className="d">MAR</div>
                  </div>
                  <img
                    alt=""
                    src="images/news/pic-blog-3.jpg"
                    className="lazy"
                  />
                </div>
                <div className="post-text">
                  <h4>
                    <a href="news-single.html">
                      Holiday Tips For Backpacker
                      <span />
                    </a>
                  </h4>
                  <p>
                    For adventure seekers and budget-conscious travelers,
                    backpacking offers a thrilling and immersive way to explore
                    the world. Whether you're embarking..
                  </p>
                  <a className="btn-line" href="#">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="text-light jarallax" aria-label="section">
      <img src="images/background/3.jpg" alt="" className="jarallax-img" />
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <h1>Let's Your Adventure Begin</h1>
            <div className="spacer-20" />
          </div>
          <div className="col-md-3">
            <i className="fa fa-trophy de-icon mb20" />
            <h4>First Class Services</h4>
            <p>
              Where luxury meets exceptional care, creating unforgettable
              moments and exceeding your every expectation.
            </p>
          </div>
          <div className="col-md-3">
            <i className="fa fa-road de-icon mb20" />
            <h4>24/7 road assistance</h4>
            <p>
              Reliable support when you need it most, keeping you on the move
              with confidence and peace of mind.
            </p>
          </div>
          <div className="col-md-3">
            <i className="fa fa-map-pin de-icon mb20" />
            <h4>Free Pick-Up &amp; Drop-Off</h4>
            <p>
              Enjoy free pickup and drop-off services, adding an extra layer of
              ease to your car rental experience.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="section-faq">
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <h2>Have Any Questions?</h2>
            <div className="spacer-20" />
          </div>
        </div>
        <div className="row g-custom-x">
          <div className="col-md-6 wow fadeInUp">
            <div className="accordion secondary">
              <div className="accordion-section">
                <div
                  className="accordion-section-title"
                  data-tab="#accordion-1"
                >
                  How do I get started with Car Rental?
                </div>
                <div className="accordion-section-content" id="accordion-1">
                  <p>
                    At vero eos et accusamus et iusto odio dignissimos ducimus
                    qui blanditiis praesentium voluptatum deleniti atque
                    corrupti quos dolores et quas molestias excepturi sint
                    occaecati cupiditate non provident, similique sunt in culpa
                    qui officia deserunt mollitia animi, id est laborum et
                    dolorum fuga. Et harum quidem rerum facilis est et expedita
                    distinctio.
                  </p>
                </div>
                <div
                  className="accordion-section-title"
                  data-tab="#accordion-2"
                >
                  Can I rent a car with a debit card??
                </div>
                <div className="accordion-section-content" id="accordion-2">
                  <p>
                    At vero eos et accusamus et iusto odio dignissimos ducimus
                    qui blanditiis praesentium voluptatum deleniti atque
                    corrupti quos dolores et quas molestias excepturi sint
                    occaecati cupiditate non provident, similique sunt in culpa
                    qui officia deserunt mollitia animi, id est laborum et
                    dolorum fuga. Et harum quidem rerum facilis est et expedita
                    distinctio.
                  </p>
                </div>
                <div
                  className="accordion-section-title"
                  data-tab="#accordion-3"
                >
                  What kind of Car Rental do I need?
                </div>
                <div className="accordion-section-content" id="accordion-3">
                  <p>
                    At vero eos et accusamus et iusto odio dignissimos ducimus
                    qui blanditiis praesentium voluptatum deleniti atque
                    corrupti quos dolores et quas molestias excepturi sint
                    occaecati cupiditate non provident, similique sunt in culpa
                    qui officia deserunt mollitia animi, id est laborum et
                    dolorum fuga. Et harum quidem rerum facilis est et expedita
                    distinctio.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 wow fadeInUp">
            <div className="accordion secondary">
              <div className="accordion-section">
                <div
                  className="accordion-section-title"
                  data-tab="#accordion-b-4"
                >
                  What is a rental car security deposit?
                </div>
                <div className="accordion-section-content" id="accordion-b-4">
                  <p>
                    At vero eos et accusamus et iusto odio dignissimos ducimus
                    qui blanditiis praesentium voluptatum deleniti atque
                    corrupti quos dolores et quas molestias excepturi sint
                    occaecati cupiditate non provident, similique sunt in culpa
                    qui officia deserunt mollitia animi, id est laborum et
                    dolorum fuga. Et harum quidem rerum facilis est et expedita
                    distinctio.
                  </p>
                </div>
                <div
                  className="accordion-section-title"
                  data-tab="#accordion-b-5"
                >
                  Can I cancel or modify my reservation?
                </div>
                <div className="accordion-section-content" id="accordion-b-5">
                  <p>
                    At vero eos et accusamus et iusto odio dignissimos ducimus
                    qui blanditiis praesentium voluptatum deleniti atque
                    corrupti quos dolores et quas molestias excepturi sint
                    occaecati cupiditate non provident, similique sunt in culpa
                    qui officia deserunt mollitia animi, id est laborum et
                    dolorum fuga. Et harum quidem rerum facilis est et expedita
                    distinctio.
                  </p>
                </div>
                <div
                  className="accordion-section-title"
                  data-tab="#accordion-b-6"
                >
                  Is it possible to extend my rental period?
                </div>
                <div className="accordion-section-content" id="accordion-b-6">
                  <p>
                    At vero eos et accusamus et iusto odio dignissimos ducimus
                    qui blanditiis praesentium voluptatum deleniti atque
                    corrupti quos dolores et quas molestias excepturi sint
                    occaecati cupiditate non provident, similique sunt in culpa
                    qui officia deserunt mollitia animi, id est laborum et
                    dolorum fuga. Et harum quidem rerum facilis est et expedita
                    distinctio.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      aria-label="section"
      className="pt40 pb40 text-light"
      data-bgcolor="#111111"
    >
      {/* <div className="wow fadeInRight d-flex">
        <div className="de-marquee-list">
          <div className="d-item">
            <span className="d-item-txt">SUV</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
            <span className="d-item-txt">Hatchback</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
            <span className="d-item-txt">Crossover</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
            <span className="d-item-txt">Convertible</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
            <span className="d-item-txt">Sedan</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
            <span className="d-item-txt">Sports Car</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
            <span className="d-item-txt">Coupe</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
            <span className="d-item-txt">Minivan</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
            <span className="d-item-txt">Station Wagon</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
            <span className="d-item-txt">Truck</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
            <span className="d-item-txt">Minivans</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
            <span className="d-item-txt">Exotic Cars</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
          </div>
        </div>
        <div className="de-marquee-list">
          <div className="d-item">
            <span className="d-item-txt">SUV</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
            <span className="d-item-txt">Hatchback</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
            <span className="d-item-txt">Crossover</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
            <span className="d-item-txt">Convertible</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
            <span className="d-item-txt">Sedan</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
            <span className="d-item-txt">Sports Car</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
            <span className="d-item-txt">Coupe</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
            <span className="d-item-txt">Minivan</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
            <span className="d-item-txt">Station Wagon</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
            <span className="d-item-txt">Truck</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
            <span className="d-item-txt">Minivans</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
            <span className="d-item-txt">Exotic Cars</span>
            <span className="d-item-display">
              <i className="d-item-dot" />
            </span>
          </div>
        </div>
      </div> */}
    </section>
  </div>
  {/* content close */}
  <Footer/>
</>

  )
}

export default DarkHome1
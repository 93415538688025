import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function Model() {
    return (
        <>
            <Header />
                {/* content begin */}
                <div className="no-bottom no-top" id="content">
                    <div id="top" />
                    {/* section begin */}
                    <section id="subheader" className="jarallax text-light">
                        <img
                            src="images/background/subheader.jpg"
                            className="jarallax-img"
                            alt=""
                        />
                        <div className="center-y relative text-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <h1>Modal</h1>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* section close */}
                    {/* section begin */}
                    <section aria-label="section">
                        <div className="container">
                            <div className="row g-custom-x">
                                <div className="col-md-4 mb60">
                                    <h4>Modal demo</h4>
                                    <p>
                                        Live demo Toggle a working modal demo by clicking the button
                                        below. It will slide down and fade in from the top of the page.
                                    </p>
                                    <div className="spacer10" />
                                    {/* Button trigger modal */}
                                    <button
                                        type="button"
                                        className="btn-main"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                    >
                                        Launch demo modal
                                    </button>
                                    {/* Modal */}
                                    <div
                                        className="modal fade"
                                        id="exampleModal"
                                        tabIndex={-1}
                                        aria-labelledby="exampleModalLabel"
                                        aria-hidden="true"
                                    >
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">
                                                        Modal title
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="btn-close"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                    />
                                                </div>
                                                <div className="modal-body">
                                                    Woohoo, you're reading this text in a modal!
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn-main"
                                                        data-bs-dismiss="modal"
                                                    >
                                                        Close
                                                    </button>
                                                    <button type="button" className="btn-main">
                                                        Save changes
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* section close */}
                </div>
                {/* content close */}
                <a href="#" id="back-to-top" />
            <Footer />
        </>

    )
}

export default Model
import React from 'react'

function Infinitemarquee() {
  return (
    <marquee>
        <div className="de-marquee-list d-marquee-small ">
                    <div className="d-item">
                        <span className="d-item-txt">SUV</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                        <span className="d-item-txt">Hatchback</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                        <span className="d-item-txt">Crossover</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                        <span className="d-item-txt">Convertible</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                        <span className="d-item-txt">Sedan</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                        <span className="d-item-txt">Sports Car</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                        <span className="d-item-txt">Coupe</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                        <span className="d-item-txt">Minivan</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                        <span className="d-item-txt">Station Wagon</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                        <span className="d-item-txt">Truck</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                        <span className="d-item-txt">Minivans</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                        <span className="d-item-txt">Exotic Cars</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                    </div>
                </div>
                {/* <div className="de-marquee-list d-marquee-small">
                    <div className="d-item">
                        <span className="d-item-txt">SUV</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                        <span className="d-item-txt">Hatchback</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                        <span className="d-item-txt">Crossover</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                        <span className="d-item-txt">Convertible</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                        <span className="d-item-txt">Sedan</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                        <span className="d-item-txt">Sports Car</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                        <span className="d-item-txt">Coupe</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                        <span className="d-item-txt">Minivan</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                        <span className="d-item-txt">Station Wagon</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                        <span className="d-item-txt">Truck</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                        <span className="d-item-txt">Minivans</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                        <span className="d-item-txt">Exotic Cars</span>
                        <span className="d-item-display">
                            <i className="d-item-dot" />
                        </span>
                    </div>
                </div> */}
    </marquee>
  )
}

export default Infinitemarquee
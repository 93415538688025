import React from 'react'
import Header from '../Components/Header';
import Footer from '../Components/Footer';

function DarkHome() {
    return (
        <>
            <Header />
            {/* content begin */}
            <div className="no-bottom no-top" id="content">
                <div id="top" />
                <section
                    id="section-hero"
                    aria-label="section"
                    className="jarallax text-light"
                >
                    <img src="images/background/15.jpg" className="jarallax-img" alt="" />
                    <div className="container">
                        <div className="row">
                            <div className="spacer-double" />
                            <div className="col-lg-5 text-light">
                                <h2 className="mb-2">
                                    Looking for a <span className="id-color">vehicle</span>? You're at
                                    the right place.
                                </h2>
                                <div className="spacer-single" />
                            </div>
                            <div className="col-lg-3 offset-lg-4">
                                <blockquote className="de-quote-small">
                                    <div className="d-rating">
                                        <i className="fa fa-star" />
                                        <i className="fa fa-star" />
                                        <i className="fa fa-star" />
                                        <i className="fa fa-star" />
                                        <i className="fa fa-star" />
                                    </div>
                                    Excellent Service! Endorsed by industry experts, Rentaly is the
                                    Car Rental solution you can trust!
                                    <span className="by">Kanesha Keyton</span>
                                </blockquote>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="spacer-single sm-hide" />
                                <div
                                    className="p-4 rounded-3 shadow-soft"
                                    data-bgcolor="rgba(34, 34, 34, .6)"
                                >
                                    <form name="contactForm" id="contact_form" method="post">
                                        <div id="step-1" className="row">
                                            <div className="col-lg-4 mb-sm-30">
                                                <h5>Pick Up Location</h5>
                                                <input
                                                    type="text"
                                                    name="PickupLocation"
                                                    onfocus="geolocate()"
                                                    placeholder="Enter your pickup location"
                                                    id="autocomplete"
                                                    autoComplete="off"
                                                    className="form-control"
                                                />
                                                <div className="jls-address-preview jls-address-preview--hidden">
                                                    <div className="jls-address-preview__header"></div>
                                                </div>
                                                <div className="spacer-20" />
                                                <h5>Pick Up Date &amp; Time</h5>
                                                <div className="date-time-field">
                                                    <input
                                                        type="text"
                                                        id="date-picker"
                                                        name="Pick Up Date"
                                                        defaultValue=""
                                                    />
                                                    <select name="Pick Up Time" id="pickup-time">
                                                        <option selected="" disabled="" value="Select time">
                                                            Time
                                                        </option>
                                                        <option value="00:00">00:00</option>
                                                        <option value="00:30">00:30</option>
                                                        <option value="01:00">01:00</option>
                                                        <option value="01:30">01:30</option>
                                                        <option value="02:00">02:00</option>
                                                        <option value="02:30">02:30</option>
                                                        <option value="03:00">03:00</option>
                                                        <option value="03:30">03:30</option>
                                                        <option value="04:00">04:00</option>
                                                        <option value="04:30">04:30</option>
                                                        <option value="05:00">05:00</option>
                                                        <option value="05:30">05:30</option>
                                                        <option value="06:00">06:00</option>
                                                        <option value="06:30">06:30</option>
                                                        <option value="07:00">07:00</option>
                                                        <option value="07:30">07:30</option>
                                                        <option value="08:00">08:00</option>
                                                        <option value="08:30">08:30</option>
                                                        <option value="09:00">09:00</option>
                                                        <option value="09:30">09:30</option>
                                                        <option value="10:00">10:00</option>
                                                        <option value="10:30">10:30</option>
                                                        <option value="11:00">11:00</option>
                                                        <option value="11:30">11:30</option>
                                                        <option value="12:00">12:00</option>
                                                        <option value="12:30">12:30</option>
                                                        <option value="13:00">13:00</option>
                                                        <option value="13:30">13:30</option>
                                                        <option value="14:00">14:00</option>
                                                        <option value="14:30">14:30</option>
                                                        <option value="15:00">15:00</option>
                                                        <option value="15:30">15:30</option>
                                                        <option value="16:00">16:00</option>
                                                        <option value="16:30">16:30</option>
                                                        <option value="17:00">17:00</option>
                                                        <option value="17:30">17:30</option>
                                                        <option value="18:00">18:00</option>
                                                        <option value="18:30">18:30</option>
                                                        <option value="19:00">19:00</option>
                                                        <option value="19:30">19:30</option>
                                                        <option value="20:00">20:00</option>
                                                        <option value="20:30">20:30</option>
                                                        <option value="21:00">21:00</option>
                                                        <option value="21:30">21:30</option>
                                                        <option value="22:00">22:00</option>
                                                        <option value="22:30">22:30</option>
                                                        <option value="23:00">23:00</option>
                                                        <option value="23:30">23:30</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-sm-30">
                                                <h5>Drop Off Location</h5>
                                                <input
                                                    type="text"
                                                    name="DropoffLocation"
                                                    onfocus="geolocate()"
                                                    placeholder="Enter your dropoff location"
                                                    id="autocomplete2"
                                                    autoComplete="off"
                                                    className="form-control"
                                                />
                                                <div className="jls-address-preview jls-address-preview--hidden">
                                                    <div className="jls-address-preview__header"></div>
                                                </div>
                                                <div className="spacer-20" />
                                                <h5>Return Date &amp; Time</h5>
                                                <div className="date-time-field">
                                                    <input
                                                        type="text"
                                                        id="date-picker-2"
                                                        name="Collection Date"
                                                        defaultValue=""
                                                    />
                                                    <select name="Collection Time" id="collection-time">
                                                        <option selected="" disabled="" value="Select time">
                                                            Time
                                                        </option>
                                                        <option value="00:00">00:00</option>
                                                        <option value="00:30">00:30</option>
                                                        <option value="01:00">01:00</option>
                                                        <option value="01:30">01:30</option>
                                                        <option value="02:00">02:00</option>
                                                        <option value="02:30">02:30</option>
                                                        <option value="03:00">03:00</option>
                                                        <option value="03:30">03:30</option>
                                                        <option value="04:00">04:00</option>
                                                        <option value="04:30">04:30</option>
                                                        <option value="05:00">05:00</option>
                                                        <option value="05:30">05:30</option>
                                                        <option value="06:00">06:00</option>
                                                        <option value="06:30">06:30</option>
                                                        <option value="07:00">07:00</option>
                                                        <option value="07:30">07:30</option>
                                                        <option value="08:00">08:00</option>
                                                        <option value="08:30">08:30</option>
                                                        <option value="09:00">09:00</option>
                                                        <option value="09:30">09:30</option>
                                                        <option value="10:00">10:00</option>
                                                        <option value="10:30">10:30</option>
                                                        <option value="11:00">11:00</option>
                                                        <option value="11:30">11:30</option>
                                                        <option value="12:00">12:00</option>
                                                        <option value="12:30">12:30</option>
                                                        <option value="13:00">13:00</option>
                                                        <option value="13:30">13:30</option>
                                                        <option value="14:00">14:00</option>
                                                        <option value="14:30">14:30</option>
                                                        <option value="15:00">15:00</option>
                                                        <option value="15:30">15:30</option>
                                                        <option value="16:00">16:00</option>
                                                        <option value="16:30">16:30</option>
                                                        <option value="17:00">17:00</option>
                                                        <option value="17:30">17:30</option>
                                                        <option value="18:00">18:00</option>
                                                        <option value="18:30">18:30</option>
                                                        <option value="19:00">19:00</option>
                                                        <option value="19:30">19:30</option>
                                                        <option value="20:00">20:00</option>
                                                        <option value="20:30">20:30</option>
                                                        <option value="21:00">21:00</option>
                                                        <option value="21:30">21:30</option>
                                                        <option value="22:00">22:00</option>
                                                        <option value="22:30">22:30</option>
                                                        <option value="23:00">23:00</option>
                                                        <option value="23:30">23:30</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb30">
                                                <h5>Vehicle Type</h5>
                                                <select
                                                    name="Vehicle Type"
                                                    id="vehicle_type"
                                                    className="form-control"
                                                >
                                                    <option
                                                        value="option-1"
                                                        data-src="images/select-form/car.png"
                                                    >
                                                        Car
                                                    </option>
                                                    <option
                                                        value="option-2"
                                                        data-src="images/select-form/van.png"
                                                    >
                                                        Van
                                                    </option>
                                                    <option
                                                        value="option-3"
                                                        data-src="images/select-form/minibus.png"
                                                    >
                                                        Minibus
                                                    </option>
                                                    <option
                                                        value="option-3"
                                                        data-src="images/select-form/sportscar.png"
                                                    >
                                                        Prestige
                                                    </option>
                                                </select>
                                                <input
                                                    type="submit"
                                                    id="send_message"
                                                    defaultValue="Find a Vehicle"
                                                    className="btn-main btn-fullwidth pull-right"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="spacer-double" />
                        <div className="row">
                            <div className="col-lg-12 text-light">
                                <div className="container-timeline">
                                    <ul>
                                        <li>
                                            <h4>Choose a vehicle</h4>
                                            <p>
                                                Unlock unparalleled adventures and memorable journeys with
                                                our vast fleet of vehicles tailored to suit every need,
                                                taste, and destination.
                                            </p>
                                        </li>
                                        <li>
                                            <h4>Pick location &amp; date</h4>
                                            <p>
                                                Pick your ideal location and date, and let us take you on a
                                                journey filled with convenience, flexibility, and
                                                unforgettable experiences.
                                            </p>
                                        </li>
                                        <li>
                                            <h4>Make a booking</h4>
                                            <p>
                                                Secure your reservation with ease, unlocking a world of
                                                possibilities and embarking on your next adventure with
                                                confidence.
                                            </p>
                                        </li>
                                        <li>
                                            <h4>Sit back &amp; relax</h4>
                                            <p>
                                                Hassle-free convenience as we take care of every detail,
                                                allowing you to unwind and embrace a journey filled comfort.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <section id="section-cars">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 offset-lg-3 text-center">
                            <span className="subtitle">Enjoy Your Ride</span>
                            <h2>Our Vehicle Fleet</h2>
                            <p>
                                Driving your dreams to reality with an exquisite fleet of versatile
                                vehicles for unforgettable journeys.
                            </p>
                            <div className="spacer-20" />
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div id="items-carousel-alt-2" className="owl-carousel wow fadeIn">
                            <div className="col-lg-12">
                                <div className="de-item alt-2 mb30">
                                    <div className="d-img">
                                        <img
                                            src="images/cars-alt/jeep-renegade.png"
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                    <div className="d-info">
                                        <div className="d-text">
                                            <h4>Jeep Renegade</h4>
                                            <div className="d-item_like">
                                                <i className="fa fa-heart" />
                                                <span>74</span>
                                            </div>
                                            <div className="d-atr-group">
                                                <span className="d-atr">
                                                    <img src="images/icons/1-green.svg" alt="" />5
                                                </span>
                                                <span className="d-atr">
                                                    <img src="images/icons/2-green.svg" alt="" />2
                                                </span>
                                                <span className="d-atr">
                                                    <img src="images/icons/3-green.svg" alt="" />4
                                                </span>
                                                <span className="d-atr">
                                                    <img src="images/icons/4-green.svg" alt="" />
                                                    SUV
                                                </span>
                                            </div>
                                            <div className="d-price">
                                                Daily rate from <span>$265</span>
                                                <a className="btn-main" href="02_dark-car-single.html">
                                                    Rent Now
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="de-item alt-2 mb30">
                                    <div className="d-img">
                                        <img
                                            src="images/cars-alt/bmw-m5.png"
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                    <div className="d-info">
                                        <div className="d-text">
                                            <h4>BMW M2</h4>
                                            <div className="d-item_like">
                                                <i className="fa fa-heart" />
                                                <span>36</span>
                                            </div>
                                            <div className="d-atr-group">
                                                <span className="d-atr">
                                                    <img src="images/icons/1-green.svg" alt="" />5
                                                </span>
                                                <span className="d-atr">
                                                    <img src="images/icons/2-green.svg" alt="" />2
                                                </span>
                                                <span className="d-atr">
                                                    <img src="images/icons/3-green.svg" alt="" />4
                                                </span>
                                                <span className="d-atr">
                                                    <img src="images/icons/4-green.svg" alt="" />
                                                    Sedan
                                                </span>
                                            </div>
                                            <div className="d-price">
                                                Daily rate from <span>$244</span>
                                                <a className="btn-main" href="02_dark-car-single.html">
                                                    Rent Now
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="de-item alt-2 mb30">
                                    <div className="d-img">
                                        <img
                                            src="images/cars-alt/ferrari-enzo.png"
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                    <div className="d-info">
                                        <div className="d-text">
                                            <h4>Ferarri Enzo</h4>
                                            <div className="d-item_like">
                                                <i className="fa fa-heart" />
                                                <span>85</span>
                                            </div>
                                            <div className="d-atr-group">
                                                <span className="d-atr">
                                                    <img src="images/icons/1-green.svg" alt="" />5
                                                </span>
                                                <span className="d-atr">
                                                    <img src="images/icons/2-green.svg" alt="" />2
                                                </span>
                                                <span className="d-atr">
                                                    <img src="images/icons/3-green.svg" alt="" />4
                                                </span>
                                                <span className="d-atr">
                                                    <img src="images/icons/4-green.svg" alt="" />
                                                    Exotic Car
                                                </span>
                                            </div>
                                            <div className="d-price">
                                                Daily rate from <span>$167</span>
                                                <a className="btn-main" href="02_dark-car-single.html">
                                                    Rent Now
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="de-item alt-2 mb30">
                                    <div className="d-img">
                                        <img
                                            src="images/cars-alt/ford-raptor.png"
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                    <div className="d-info">
                                        <div className="d-text">
                                            <h4>Ford Raptor</h4>
                                            <div className="d-item_like">
                                                <i className="fa fa-heart" />
                                                <span>59</span>
                                            </div>
                                            <div className="d-atr-group">
                                                <span className="d-atr">
                                                    <img src="images/icons/1-green.svg" alt="" />5
                                                </span>
                                                <span className="d-atr">
                                                    <img src="images/icons/2-green.svg" alt="" />2
                                                </span>
                                                <span className="d-atr">
                                                    <img src="images/icons/3-green.svg" alt="" />4
                                                </span>
                                                <span className="d-atr">
                                                    <img src="images/icons/4-green.svg" alt="" />
                                                    Truck
                                                </span>
                                            </div>
                                            <div className="d-price">
                                                Daily rate from <span>$147</span>
                                                <a className="btn-main" href="02_dark-car-single.html">
                                                    Rent Now
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="de-item alt-2 mb30">
                                    <div className="d-img">
                                        <img
                                            src="images/cars-alt/mini-cooper.png"
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                    <div className="d-info">
                                        <div className="d-text">
                                            <h4>Mini Cooper</h4>
                                            <div className="d-item_like">
                                                <i className="fa fa-heart" />
                                                <span>19</span>
                                            </div>
                                            <div className="d-atr-group">
                                                <span className="d-atr">
                                                    <img src="images/icons/1-green.svg" alt="" />5
                                                </span>
                                                <span className="d-atr">
                                                    <img src="images/icons/2-green.svg" alt="" />2
                                                </span>
                                                <span className="d-atr">
                                                    <img src="images/icons/3-green.svg" alt="" />4
                                                </span>
                                                <span className="d-atr">
                                                    <img src="images/icons/4-green.svg" alt="" />
                                                    Hatchback
                                                </span>
                                            </div>
                                            <div className="d-price">
                                                Daily rate from <span>$238</span>
                                                <a className="btn-main" href="02_dark-car-single.html">
                                                    Rent Now
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="de-item alt-2 mb30">
                                    <div className="d-img">
                                        <img
                                            src="images/cars-alt/vw-polo.png"
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                    <div className="d-info">
                                        <div className="d-text">
                                            <h4>VW Polo</h4>
                                            <div className="d-item_like">
                                                <i className="fa fa-heart" />
                                                <span>79</span>
                                            </div>
                                            <div className="d-atr-group">
                                                <span className="d-atr">
                                                    <img src="images/icons/1-green.svg" alt="" />5
                                                </span>
                                                <span className="d-atr">
                                                    <img src="images/icons/2-green.svg" alt="" />2
                                                </span>
                                                <span className="d-atr">
                                                    <img src="images/icons/3-green.svg" alt="" />4
                                                </span>
                                                <span className="d-atr">
                                                    <img src="images/icons/4-green.svg" alt="" />
                                                    Hatchback
                                                </span>
                                            </div>
                                            <div className="d-price">
                                                Daily rate from <span>$106</span>
                                                <a className="btn-main" href="02_dark-car-single.html">
                                                    Rent Now
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-light jarallax">
                <img src="images/background/9.jpg" className="jarallax-img" alt="" />
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInRight">
                            <h2>
                                We offer customers a wide range of commercial cars and luxury cars
                                for any occasion.
                            </h2>
                        </div>
                        <div className="col-lg-6 wow fadeInLeft">
                            At our car rental agency, we believe that everyone deserves to
                            experience the pleasure of driving a reliable and comfortable vehicle,
                            regardless of their budget. We have curated a diverse fleet of
                            well-maintained cars, ranging from sleek sedans to spacious SUVs, all
                            at competitive prices. With our streamlined rental process, you can
                            quickly and conveniently reserve your desired vehicle. Whether you
                            need transportation for a business trip, family vacation, or simply
                            want to enjoy a weekend getaway, we have flexible rental options to
                            accommodate your schedule.
                        </div>
                    </div>
                    <div className="spacer-double" />
                    <div className="row text-center">
                        <div className="col-md-3 col-sm-6 mb-sm-30">
                            <div
                                className="de_count transparent text-light wow fadeInUp"
                                data-bgcolor="rgba(32, 32, 32, .5)"
                            >
                                <h3 className="timer" data-to={15425} data-speed={3000}>
                                    0
                                </h3>
                                Completed Orders
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-sm-30">
                            <div
                                className="de_count transparent text-light wow fadeInUp"
                                data-bgcolor="rgba(32, 32, 32, .5)"
                            >
                                <h3 className="timer" data-to={8745} data-speed={3000}>
                                    0
                                </h3>
                                Happy Customers
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-sm-30">
                            <div
                                className="de_count transparent text-light wow fadeInUp"
                                data-bgcolor="rgba(32, 32, 32, .5)"
                            >
                                <h3 className="timer" data-to={235} data-speed={3000}>
                                    0
                                </h3>
                                Vehicles Fleet
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-sm-30">
                            <div
                                className="de_count transparent text-light wow fadeInUp"
                                data-bgcolor="rgba(32, 32, 32, .5)"
                            >
                                <h3 className="timer" data-to={15} data-speed={3000}>
                                    0
                                </h3>
                                Years Experience
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section aria-label="section" className="no-bottom">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 offset-lg-3 text-center">
                            <span className="subtitle">Why Choose Us</span>
                            <h2>Our Features</h2>
                            <p>
                                Discover a world of convenience, safety, and customization, paving
                                the way for unforgettable adventures and seamless mobility
                                solutions.
                            </p>
                            <div className="spacer-20" />
                        </div>
                        <div className="clearfix" />
                        <div className="col-lg-3">
                            <div
                                className="box-icon s2 p-small mb20 wow fadeInRight"
                                data-wow-delay=".5s"
                            >
                                <i className="fa bg-color fa-trophy" />
                                <div className="d-inner">
                                    <h4>First class services</h4>
                                    Where luxury meets exceptional care, creating unforgettable
                                    moments and exceeding your every expectation.
                                </div>
                            </div>
                            <div
                                className="box-icon s2 p-small mb20 wow fadeInL fadeInRight"
                                data-wow-delay=".75s"
                            >
                                <i className="fa bg-color fa-road" />
                                <div className="d-inner">
                                    <h4>24/7 road assistance</h4>
                                    Reliable support when you need it most, keeping you on the move
                                    with confidence and peace of mind.
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img
                                src="images/misc/car-2.png"
                                alt=""
                                className="img-fluid wow fadeInUp"
                            />
                        </div>
                        <div className="col-lg-3">
                            <div
                                className="box-icon s2 d-invert p-small mb20 wow fadeInL fadeInLeft"
                                data-wow-delay="1s"
                            >
                                <i className="fa bg-color fa-tag" />
                                <div className="d-inner">
                                    <h4>Quality at Minimum Expense</h4>
                                    Unlocking affordable brilliance with elevating quality while
                                    minimizing costs for maximum value.
                                </div>
                            </div>
                            <div
                                className="box-icon s2 d-invert p-small mb20 wow fadeInL fadeInLeft"
                                data-wow-delay="1.25s"
                            >
                                <i className="fa bg-color fa-map-pin" />
                                <div className="d-inner">
                                    <h4>Free Pick-Up &amp; Drop-Off</h4>
                                    Enjoy free pickup and drop-off services, adding an extra layer of
                                    ease to your car rental experience.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="section-testimonials" className="no-top no-bottom">
                <div className="container">
                    <div className="row g-4 align-items-center">
                        <div className="col-md-4 wow fadeInUp">
                            <div className="de-image-text">
                                <div className="d-text">
                                    <div className="d-rating mb10">
                                        <i className="fa fa-star" />
                                        <i className="fa fa-star" />
                                        <i className="fa fa-star" />
                                        <i className="fa fa-star" />
                                        <i className="fa fa-star" />
                                    </div>
                                    <h4>Excellent Service! Car Rent Service!</h4>
                                    <blockquote>
                                        I have been using Rentaly for my Car Rental needs for over 5
                                        years now. I have never had any problems with their service.
                                        Their customer support is always responsive and helpful. I would
                                        recommend Rentaly to anyone looking for a reliable Car Rental
                                        provider.
                                        <span className="by">Stepanie Hutchkiss</span>
                                    </blockquote>
                                </div>
                                <img src="images/testimonial/1.jpg" className="img-fluid" alt="" />
                            </div>
                        </div>
                        <div className="col-md-4 wow fadeInUp">
                            <div className="spacer-double sm-hide" />
                            <div className="spacer-double sm-hide" />
                            <div className="de-image-text">
                                <div className="d-text">
                                    <div className="d-rating mb10">
                                        <i className="fa fa-star" />
                                        <i className="fa fa-star" />
                                        <i className="fa fa-star" />
                                        <i className="fa fa-star" />
                                        <i className="fa fa-star" />
                                    </div>
                                    <h4>Excellent Service! Car Rent Service!</h4>
                                    <blockquote>
                                        We have been using Rentaly for our trips needs for several years
                                        now and have always been happy with their service. Their
                                        customer support is Excellent Service! and they are always
                                        available to help with any issues we have. Their prices are also
                                        very competitive.
                                        <span className="by">Jovan Reels</span>
                                    </blockquote>
                                </div>
                                <img src="images/testimonial/2.jpg" className="img-fluid" alt="" />
                            </div>
                        </div>
                        <div className="col-md-4 wow fadeInUp">
                            <div className="spacer-double sm-hide" />
                            <div className="spacer-double sm-hide" />
                            <div className="spacer-double sm-hide" />
                            <div className="spacer-double sm-hide" />
                            <div className="de-image-text">
                                <div className="d-text">
                                    <div className="d-rating mb10">
                                        <i className="fa fa-star" />
                                        <i className="fa fa-star" />
                                        <i className="fa fa-star" />
                                        <i className="fa fa-star" />
                                        <i className="fa fa-star" />
                                    </div>
                                    <h4>Excellent Service! Car Rent Service!</h4>
                                    <blockquote>
                                        Endorsed by industry experts, Rentaly is the Car Rental solution
                                        you can trust. With years of experience in the field, we provide
                                        fast, reliable and secure Car Rental services.
                                        <span className="by">Kanesha Keyton</span>
                                    </blockquote>
                                </div>
                                <img src="images/testimonial/3.jpg" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="section-news">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 offset-lg-3 text-center">
                            <span className="subtitle">Latest From Us</span>
                            <h2>News &amp; Promo</h2>
                            <p>
                                Breaking news, fresh perspectives, and in-depth coverage - stay
                                ahead with our latest news, insights, and analysis.
                            </p>
                            <div className="spacer-20" />
                        </div>
                        <div className="col-lg-4 mb10">
                            <div className="bloglist s2 item">
                                <div className="post-content">
                                    <div className="post-image">
                                        <div className="date-box">
                                            <div className="m">10</div>
                                            <div className="d">MAR</div>
                                        </div>
                                        <img alt="" src="images/news/pic-blog-1.jpg" className="lazy" />
                                    </div>
                                    <div className="post-text">
                                        <h4>
                                            <a href="news-single.html">
                                                Enjoy Best Travel Experience
                                                <span />
                                            </a>
                                        </h4>
                                        <p>
                                            Traveling is an enriching experience that allows us to explore
                                            new destinations, immerse ourselves in different cultures, and
                                            create lifelong memories.
                                        </p>
                                        <a className="btn-line" href="#">
                                            Read More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb10">
                            <div className="bloglist s2 item">
                                <div className="post-content">
                                    <div className="post-image">
                                        <div className="date-box">
                                            <div className="m">12</div>
                                            <div className="d">MAR</div>
                                        </div>
                                        <img alt="" src="images/news/pic-blog-2.jpg" className="lazy" />
                                    </div>
                                    <div className="post-text">
                                        <h4>
                                            <a href="news-single.html">
                                                The Future of Car Rent
                                                <span />
                                            </a>
                                        </h4>
                                        <p>
                                            As technology continues to advance at a rapid pace, the car
                                            rental industry is poised for a transformative shift. The
                                            future of car rental promises..
                                        </p>
                                        <a className="btn-line" href="#">
                                            Read More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb10">
                            <div className="bloglist s2 item">
                                <div className="post-content">
                                    <div className="post-image">
                                        <div className="date-box">
                                            <div className="m">14</div>
                                            <div className="d">MAR</div>
                                        </div>
                                        <img alt="" src="images/news/pic-blog-3.jpg" className="lazy" />
                                    </div>
                                    <div className="post-text">
                                        <h4>
                                            <a href="news-single.html">
                                                Holiday Tips For Backpacker
                                                <span />
                                            </a>
                                        </h4>
                                        <p>
                                            For adventure seekers and budget-conscious travelers,
                                            backpacking offers a thrilling and immersive way to explore
                                            the world. Whether you're embarking..
                                        </p>
                                        <a className="btn-line" href="#">
                                            Read More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-light jarallax" aria-label="section">
                <img src="images/background/3.jpg" alt="" className="jarallax-img" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <h1>Let's Your Adventure Begin</h1>
                            <div className="spacer-20" />
                        </div>
                        <div className="col-md-3">
                            <i className="fa fa-trophy de-icon mb20" />
                            <h4>First Class Services</h4>
                            <p>
                                Where luxury meets exceptional care, creating unforgettable moments
                                and exceeding your every expectation.
                            </p>
                        </div>
                        <div className="col-md-3">
                            <i className="fa fa-road de-icon mb20" />
                            <h4>24/7 road assistance</h4>
                            <p>
                                Reliable support when you need it most, keeping you on the move with
                                confidence and peace of mind.
                            </p>
                        </div>
                        <div className="col-md-3">
                            <i className="fa fa-map-pin de-icon mb20" />
                            <h4>Free Pick-Up &amp; Drop-Off</h4>
                            <p>
                                Enjoy free pickup and drop-off services, adding an extra layer of
                                ease to your car rental experience.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="section-faq">
                <div className="container">
                    <div className="row">
                        <div className="col text-center">
                            <h2>Have Any Questions?</h2>
                            <div className="spacer-20" />
                        </div>
                    </div>
                    <div className="row g-custom-x">
                        <div className="col-md-6 wow fadeInUp">
                            <div className="accordion secondary">
                                <div className="accordion-section">
                                    <div className="accordion-section-title" data-tab="#accordion-1">
                                        How do I get started with Car Rental?
                                    </div>
                                    <div className="accordion-section-content" id="accordion-1">
                                        <p>
                                            At vero eos et accusamus et iusto odio dignissimos ducimus qui
                                            blanditiis praesentium voluptatum deleniti atque corrupti quos
                                            dolores et quas molestias excepturi sint occaecati cupiditate
                                            non provident, similique sunt in culpa qui officia deserunt
                                            mollitia animi, id est laborum et dolorum fuga. Et harum
                                            quidem rerum facilis est et expedita distinctio.
                                        </p>
                                    </div>
                                    <div className="accordion-section-title" data-tab="#accordion-2">
                                        Can I rent a car with a debit card??
                                    </div>
                                    <div className="accordion-section-content" id="accordion-2">
                                        <p>
                                            At vero eos et accusamus et iusto odio dignissimos ducimus qui
                                            blanditiis praesentium voluptatum deleniti atque corrupti quos
                                            dolores et quas molestias excepturi sint occaecati cupiditate
                                            non provident, similique sunt in culpa qui officia deserunt
                                            mollitia animi, id est laborum et dolorum fuga. Et harum
                                            quidem rerum facilis est et expedita distinctio.
                                        </p>
                                    </div>
                                    <div className="accordion-section-title" data-tab="#accordion-3">
                                        What kind of Car Rental do I need?
                                    </div>
                                    <div className="accordion-section-content" id="accordion-3">
                                        <p>
                                            At vero eos et accusamus et iusto odio dignissimos ducimus qui
                                            blanditiis praesentium voluptatum deleniti atque corrupti quos
                                            dolores et quas molestias excepturi sint occaecati cupiditate
                                            non provident, similique sunt in culpa qui officia deserunt
                                            mollitia animi, id est laborum et dolorum fuga. Et harum
                                            quidem rerum facilis est et expedita distinctio.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 wow fadeInUp">
                            <div className="accordion secondary">
                                <div className="accordion-section">
                                    <div
                                        className="accordion-section-title"
                                        data-tab="#accordion-b-4"
                                    >
                                        What is a rental car security deposit?
                                    </div>
                                    <div className="accordion-section-content" id="accordion-b-4">
                                        <p>
                                            At vero eos et accusamus et iusto odio dignissimos ducimus qui
                                            blanditiis praesentium voluptatum deleniti atque corrupti quos
                                            dolores et quas molestias excepturi sint occaecati cupiditate
                                            non provident, similique sunt in culpa qui officia deserunt
                                            mollitia animi, id est laborum et dolorum fuga. Et harum
                                            quidem rerum facilis est et expedita distinctio.
                                        </p>
                                    </div>
                                    <div
                                        className="accordion-section-title"
                                        data-tab="#accordion-b-5"
                                    >
                                        Can I cancel or modify my reservation?
                                    </div>
                                    <div className="accordion-section-content" id="accordion-b-5">
                                        <p>
                                            At vero eos et accusamus et iusto odio dignissimos ducimus qui
                                            blanditiis praesentium voluptatum deleniti atque corrupti quos
                                            dolores et quas molestias excepturi sint occaecati cupiditate
                                            non provident, similique sunt in culpa qui officia deserunt
                                            mollitia animi, id est laborum et dolorum fuga. Et harum
                                            quidem rerum facilis est et expedita distinctio.
                                        </p>
                                    </div>
                                    <div
                                        className="accordion-section-title"
                                        data-tab="#accordion-b-6"
                                    >
                                        Is it possible to extend my rental period?
                                    </div>
                                    <div className="accordion-section-content" id="accordion-b-6">
                                        <p>
                                            At vero eos et accusamus et iusto odio dignissimos ducimus qui
                                            blanditiis praesentium voluptatum deleniti atque corrupti quos
                                            dolores et quas molestias excepturi sint occaecati cupiditate
                                            non provident, similique sunt in culpa qui officia deserunt
                                            mollitia animi, id est laborum et dolorum fuga. Et harum
                                            quidem rerum facilis est et expedita distinctio.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                aria-label="section"
                className="pt40 pb40 text-light"
                data-bgcolor="#111111"
            >
                {/* <div className="wow fadeInRight d-flex">
                    <div className="de-marquee-list">
                        <div className="d-item">
                            <span className="d-item-txt">SUV</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                            <span className="d-item-txt">Hatchback</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                            <span className="d-item-txt">Crossover</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                            <span className="d-item-txt">Convertible</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                            <span className="d-item-txt">Sedan</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                            <span className="d-item-txt">Sports Car</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                            <span className="d-item-txt">Coupe</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                            <span className="d-item-txt">Minivan</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                            <span className="d-item-txt">Station Wagon</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                            <span className="d-item-txt">Truck</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                            <span className="d-item-txt">Minivans</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                            <span className="d-item-txt">Exotic Cars</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                        </div>
                    </div>
                    <div className="de-marquee-list">
                        <div className="d-item">
                            <span className="d-item-txt">SUV</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                            <span className="d-item-txt">Hatchback</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                            <span className="d-item-txt">Crossover</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                            <span className="d-item-txt">Convertible</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                            <span className="d-item-txt">Sedan</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                            <span className="d-item-txt">Sports Car</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                            <span className="d-item-txt">Coupe</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                            <span className="d-item-txt">Minivan</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                            <span className="d-item-txt">Station Wagon</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                            <span className="d-item-txt">Truck</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                            <span className="d-item-txt">Minivans</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                            <span className="d-item-txt">Exotic Cars</span>
                            <span className="d-item-display">
                                <i className="d-item-dot" />
                            </span>
                        </div>
                    </div>
                </div> */}
            </section>
            {/* content close */}
            <Footer />
        </>
    )
}

export default DarkHome;
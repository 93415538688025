import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'

function Tabs() {
    return (
        <>
        <Header/>
            {/* content begin */}
            <div className="no-bottom no-top" id="content">
                <div id="top" />
                {/* section begin */}
                <section id="subheader" className="jarallax text-light">
                    <img
                        src="images/background/subheader.jpg"
                        className="jarallax-img"
                        alt=""
                    />
                    <div className="center-y relative text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h1>Tabs</h1>
                                </div>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* section close */}
                {/* section begin */}
                <section aria-label="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h3>Tab Style 1</h3>
                                <div className="spacer30" />
                                <div className="tab-default">
                                    <nav>
                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                            <button
                                                className="nav-link active"
                                                id="nav-home-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-home"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-home"
                                                aria-selected="true"
                                            >
                                                Home
                                            </button>
                                            <button
                                                className="nav-link"
                                                id="nav-profile-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-profile"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-profile"
                                                aria-selected="false"
                                            >
                                                Profile
                                            </button>
                                            <button
                                                className="nav-link"
                                                id="nav-contact-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-contact"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-contact"
                                                aria-selected="false"
                                            >
                                                Contact
                                            </button>
                                        </div>
                                    </nav>
                                    <div className="tab-content" id="nav-tabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="nav-home"
                                            role="tabpanel"
                                            aria-labelledby="nav-home-tab"
                                        >
                                            <p>
                                                Consequat occaecat ullamco amet non eiusmod nostrud dolore
                                                irure incididunt est duis anim sunt officia. Fugiat velit
                                                proident aliquip nisi incididunt nostrud exercitation
                                                proident est nisi. Irure magna elit commodo anim ex veniam
                                                culpa eiusmod id nostrud sit cupidatat in veniam ad. Eiusmod
                                                consequat eu adipisicing minim anim aliquip cupidatat culpa
                                                excepteur quis. Occaecat sit eu exercitation irure Lorem
                                                incididunt nostrud.
                                            </p>
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="nav-profile"
                                            role="tabpanel"
                                            aria-labelledby="nav-profile-tab"
                                        >
                                            <p>
                                                Ad pariatur nostrud pariatur exercitation ipsum ipsum culpa
                                                mollit commodo mollit ex. Aute sunt incididunt amet commodo
                                                est sint nisi deserunt pariatur do. Aliquip ex eiusmod
                                                voluptate exercitation cillum id incididunt elit sunt. Qui
                                                minim sit magna Lorem id et dolore velit Lorem amet
                                                exercitation duis deserunt. Anim id labore elit adipisicing
                                                ut in id occaecat pariatur ut ullamco ea tempor duis.
                                            </p>
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="nav-contact"
                                            role="tabpanel"
                                            aria-labelledby="nav-contact-tab"
                                        >
                                            <p>
                                                Est quis nulla laborum officia ad nisi ex nostrud culpa
                                                Lorem excepteur aliquip dolor aliqua irure ex. Nulla ut duis
                                                ipsum nisi elit fugiat commodo sunt reprehenderit laborum
                                                veniam eu veniam. Eiusmod minim exercitation fugiat irure ex
                                                labore incididunt do fugiat commodo aliquip sit id deserunt
                                                reprehenderit aliquip nostrud. Amet ex cupidatat excepteur
                                                aute veniam incididunt mollit cupidatat esse irure officia
                                                elit do ipsum ullamco Lorem.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h3>Tab Style 2</h3>
                                <div className="spacer30" />
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link active"
                                            id="pills-home-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-home"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-home"
                                            aria-selected="true"
                                        >
                                            Home
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="pills-profile-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-profile"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-profile"
                                            aria-selected="false"
                                        >
                                            Profile
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="pills-contact-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-contact"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-contact"
                                            aria-selected="false"
                                        >
                                            Contact
                                        </button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        id="pills-home"
                                        role="tabpanel"
                                        aria-labelledby="pills-home-tab"
                                    >
                                        <p>
                                            Consequat occaecat ullamco amet non eiusmod nostrud dolore
                                            irure incididunt est duis anim sunt officia. Fugiat velit
                                            proident aliquip nisi incididunt nostrud exercitation proident
                                            est nisi. Irure magna elit commodo anim ex veniam culpa
                                            eiusmod id nostrud sit cupidatat in veniam ad. Eiusmod
                                            consequat eu adipisicing minim anim aliquip cupidatat culpa
                                            excepteur quis. Occaecat sit eu exercitation irure Lorem
                                            incididunt nostrud.
                                        </p>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="pills-profile"
                                        role="tabpanel"
                                        aria-labelledby="pills-profile-tab"
                                    >
                                        <p>
                                            Ad pariatur nostrud pariatur exercitation ipsum ipsum culpa
                                            mollit commodo mollit ex. Aute sunt incididunt amet commodo
                                            est sint nisi deserunt pariatur do. Aliquip ex eiusmod
                                            voluptate exercitation cillum id incididunt elit sunt. Qui
                                            minim sit magna Lorem id et dolore velit Lorem amet
                                            exercitation duis deserunt. Anim id labore elit adipisicing ut
                                            in id occaecat pariatur ut ullamco ea tempor duis.
                                        </p>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="pills-contact"
                                        role="tabpanel"
                                        aria-labelledby="pills-contact-tab"
                                    >
                                        <p>
                                            Est quis nulla laborum officia ad nisi ex nostrud culpa Lorem
                                            excepteur aliquip dolor aliqua irure ex. Nulla ut duis ipsum
                                            nisi elit fugiat commodo sunt reprehenderit laborum veniam eu
                                            veniam. Eiusmod minim exercitation fugiat irure ex labore
                                            incididunt do fugiat commodo aliquip sit id deserunt
                                            reprehenderit aliquip nostrud. Amet ex cupidatat excepteur
                                            aute veniam incididunt mollit cupidatat esse irure officia
                                            elit do ipsum ullamco Lorem.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="spacer-single" />
                            <div className="col-md-6">
                                <h3>Tab Style 3</h3>
                                <div className="spacer30" />
                                <div className="row">
                                    <div className="col-3">
                                        <div
                                            className="nav flex-column nav-pills me-3"
                                            id="v-pills-tab"
                                            role="tablist"
                                            aria-orientation="vertical"
                                        >
                                            <button
                                                className="nav-link active"
                                                id="v-pills-home-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#v-pills-home"
                                                type="button"
                                                role="tab"
                                                aria-controls="v-pills-home"
                                                aria-selected="true"
                                            >
                                                Home
                                            </button>
                                            <button
                                                className="nav-link"
                                                id="v-pills-profile-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#v-pills-profile"
                                                type="button"
                                                role="tab"
                                                aria-controls="v-pills-profile"
                                                aria-selected="false"
                                            >
                                                Profile
                                            </button>
                                            <button
                                                className="nav-link"
                                                id="v-pills-messages-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#v-pills-messages"
                                                type="button"
                                                role="tab"
                                                aria-controls="v-pills-messages"
                                                aria-selected="false"
                                            >
                                                Messages
                                            </button>
                                            <button
                                                className="nav-link"
                                                id="v-pills-settings-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#v-pills-settings"
                                                type="button"
                                                role="tab"
                                                aria-controls="v-pills-settings"
                                                aria-selected="false"
                                            >
                                                Settings
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-9">
                                        <div className="tab-content" id="v-pills-tabContent">
                                            <div
                                                className="tab-pane fade show active"
                                                id="v-pills-home"
                                                role="tabpanel"
                                                aria-labelledby="v-pills-home-tab"
                                            >
                                                <p>
                                                    Consequat occaecat ullamco amet non eiusmod nostrud dolore
                                                    irure incididunt est duis anim sunt officia. Fugiat velit
                                                    proident aliquip nisi incididunt nostrud exercitation
                                                    proident est nisi. Irure magna elit commodo anim ex veniam
                                                    culpa eiusmod id nostrud sit cupidatat in veniam ad.
                                                    Eiusmod consequat eu adipisicing minim anim aliquip
                                                    cupidatat culpa excepteur quis. Occaecat sit eu
                                                    exercitation irure Lorem incididunt nostrud.
                                                </p>
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="v-pills-profile"
                                                role="tabpanel"
                                                aria-labelledby="v-pills-profile-tab"
                                            >
                                                <p>
                                                    Ad pariatur nostrud pariatur exercitation ipsum ipsum
                                                    culpa mollit commodo mollit ex. Aute sunt incididunt amet
                                                    commodo est sint nisi deserunt pariatur do. Aliquip ex
                                                    eiusmod voluptate exercitation cillum id incididunt elit
                                                    sunt. Qui minim sit magna Lorem id et dolore velit Lorem
                                                    amet exercitation duis deserunt. Anim id labore elit
                                                    adipisicing ut in id occaecat pariatur ut ullamco ea
                                                    tempor duis.
                                                </p>
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="v-pills-messages"
                                                role="tabpanel"
                                                aria-labelledby="v-pills-messages-tab"
                                            >
                                                <p>
                                                    Est quis nulla laborum officia ad nisi ex nostrud culpa
                                                    Lorem excepteur aliquip dolor aliqua irure ex. Nulla ut
                                                    duis ipsum nisi elit fugiat commodo sunt reprehenderit
                                                    laborum veniam eu veniam. Eiusmod minim exercitation
                                                    fugiat irure ex labore incididunt do fugiat commodo
                                                    aliquip sit id deserunt reprehenderit aliquip nostrud.
                                                    Amet ex cupidatat excepteur aute veniam incididunt mollit
                                                    cupidatat esse irure officia elit do ipsum ullamco Lorem.
                                                </p>
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="v-pills-settings"
                                                role="tabpanel"
                                                aria-labelledby="v-pills-settings-tab"
                                            >
                                                <p>
                                                    Consequat occaecat ullamco amet non eiusmod nostrud dolore
                                                    irure incididunt est duis anim sunt officia. Fugiat velit
                                                    proident aliquip nisi incididunt nostrud exercitation
                                                    proident est nisi. Irure magna elit commodo anim ex veniam
                                                    culpa eiusmod id nostrud sit cupidatat in veniam ad.
                                                    Eiusmod consequat eu adipisicing minim anim aliquip
                                                    cupidatat culpa excepteur quis. Occaecat sit eu
                                                    exercitation irure Lorem incididunt nostrud.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h3>Tab Style 4</h3>
                                <div className="spacer30" />
                                <div className="row">
                                    <div className="col-9">
                                        <div className="tab-content" id="v-pills-tab-2Content">
                                            <div
                                                className="tab-pane fade show active"
                                                id="v-pills-home-2"
                                                role="tabpanel"
                                                aria-labelledby="v-pills-home-tab-2"
                                            >
                                                <p>
                                                    Consequat occaecat ullamco amet non eiusmod nostrud dolore
                                                    irure incididunt est duis anim sunt officia. Fugiat velit
                                                    proident aliquip nisi incididunt nostrud exercitation
                                                    proident est nisi. Irure magna elit commodo anim ex veniam
                                                    culpa eiusmod id nostrud sit cupidatat in veniam ad.
                                                    Eiusmod consequat eu adipisicing minim anim aliquip
                                                    cupidatat culpa excepteur quis. Occaecat sit eu
                                                    exercitation irure Lorem incididunt nostrud.
                                                </p>
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="v-pills-profile-2"
                                                role="tabpanel"
                                                aria-labelledby="v-pills-profile-tab-2"
                                            >
                                                <p>
                                                    Ad pariatur nostrud pariatur exercitation ipsum ipsum
                                                    culpa mollit commodo mollit ex. Aute sunt incididunt amet
                                                    commodo est sint nisi deserunt pariatur do. Aliquip ex
                                                    eiusmod voluptate exercitation cillum id incididunt elit
                                                    sunt. Qui minim sit magna Lorem id et dolore velit Lorem
                                                    amet exercitation duis deserunt. Anim id labore elit
                                                    adipisicing ut in id occaecat pariatur ut ullamco ea
                                                    tempor duis.
                                                </p>
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="v-pills-messages-2"
                                                role="tabpanel"
                                                aria-labelledby="v-pills-messages-tab-2"
                                            >
                                                <p>
                                                    Est quis nulla laborum officia ad nisi ex nostrud culpa
                                                    Lorem excepteur aliquip dolor aliqua irure ex. Nulla ut
                                                    duis ipsum nisi elit fugiat commodo sunt reprehenderit
                                                    laborum veniam eu veniam. Eiusmod minim exercitation
                                                    fugiat irure ex labore incididunt do fugiat commodo
                                                    aliquip sit id deserunt reprehenderit aliquip nostrud.
                                                    Amet ex cupidatat excepteur aute veniam incididunt mollit
                                                    cupidatat esse irure officia elit do ipsum ullamco Lorem.
                                                </p>
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="v-pills-settings-2"
                                                role="tabpanel"
                                                aria-labelledby="v-pills-settings-tab-2"
                                            >
                                                <p>
                                                    Consequat occaecat ullamco amet non eiusmod nostrud dolore
                                                    irure incididunt est duis anim sunt officia. Fugiat velit
                                                    proident aliquip nisi incididunt nostrud exercitation
                                                    proident est nisi. Irure magna elit commodo anim ex veniam
                                                    culpa eiusmod id nostrud sit cupidatat in veniam ad.
                                                    Eiusmod consequat eu adipisicing minim anim aliquip
                                                    cupidatat culpa excepteur quis. Occaecat sit eu
                                                    exercitation irure Lorem incididunt nostrud.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div
                                            className="nav flex-column nav-pills me-3"
                                            id="v-pills-tab"
                                            role="tablist"
                                            aria-orientation="vertical"
                                        >
                                            <button
                                                className="nav-link active"
                                                id="v-pills-home-tab-2"
                                                data-bs-toggle="pill"
                                                data-bs-target="#v-pills-home-2"
                                                type="button"
                                                role="tab"
                                                aria-controls="v-pills-home"
                                                aria-selected="true"
                                            >
                                                Home
                                            </button>
                                            <button
                                                className="nav-link"
                                                id="v-pills-profile-tab-2"
                                                data-bs-toggle="pill"
                                                data-bs-target="#v-pills-profile-2"
                                                type="button"
                                                role="tab"
                                                aria-controls="v-pills-profile"
                                                aria-selected="false"
                                            >
                                                Profile
                                            </button>
                                            <button
                                                className="nav-link"
                                                id="v-pills-messages-tab-2"
                                                data-bs-toggle="pill"
                                                data-bs-target="#v-pills-messages-2"
                                                type="button"
                                                role="tab"
                                                aria-controls="v-pills-messages"
                                                aria-selected="false"
                                            >
                                                Messages
                                            </button>
                                            <button
                                                className="nav-link"
                                                id="v-pills-settings-tab-2"
                                                data-bs-toggle="pill"
                                                data-bs-target="#v-pills-settings-2"
                                                type="button"
                                                role="tab"
                                                aria-controls="v-pills-settings"
                                                aria-selected="false"
                                            >
                                                Settings
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section close */}
            </div>
            {/* content close */}
            <a href="#" id="back-to-top" />
            <Footer/>
        </>

    )
}

export default Tabs
import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function AccountFavorite() {
  return (
    <>
    <Header/>
  {/* content begin */}
  <div className="no-bottom no-top zebra" id="content">
    <div id="top" />
    {/* section begin */}
    <section id="subheader" className="jarallax text-light">
      <img src="images/background/14.jpg" className="jarallax-img" alt="" />
      <div className="center-y relative text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>My Favorite Cars</h1>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </div>
    </section>
    {/* section close */}
    <section id="section-settings" className="bg-gray-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 mb30">
            <div className="card padding30 rounded-5">
              <div className="profile_avatar">
                <div className="profile_img">
                  <img src="images/profile/1.jpg" alt="" />
                </div>
                <div className="profile_name">
                  <h4>
                    Monica Lucas
                    <span className="profile_username text-gray">
                      monica@rentaly.com
                    </span>
                  </h4>
                </div>
              </div>
              <div className="spacer-20" />
              <ul className="menu-col">
                <li>
                  <a href="/AccountDashboard">
                    <i className="fa fa-home" />
                    Dashboard
                  </a>
                </li>
                <li>
                  <a href="/AccountProfile">
                    <i className="fa fa-user" />
                    My Profile
                  </a>
                </li>
                <li>
                  <a href="/AccountBooking">
                    <i className="fa fa-calendar" />
                    My Orders
                  </a>
                </li>
                <li>
                  <a href="/AccountFavorite" className="active">
                    <i className="fa fa-calendar" />
                    My Favorite Cars
                  </a>
                </li>
                <li>
                  <a href="/Login">
                    <i className="fa fa-sign-out" />
                    Sign Out
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="de-item-list no-border mb30">
              <div className="d-img">
                <img
                  src="images/cars/jeep-renegade.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="d-info">
                <div className="d-text">
                  <h4>Jeep Renegade</h4>
                  <div className="d-atr-group">
                    <ul className="d-atr">
                      <li>
                        <span>Seats:</span>4
                      </li>
                      <li>
                        <span>Luggage:</span>2
                      </li>
                      <li>
                        <span>Doors:</span>4
                      </li>
                      <li>
                        <span>Fuel:</span>Petrol
                      </li>
                      <li>
                        <span>Horsepower:</span>500
                      </li>
                      <li>
                        <span>Engine:</span>3000
                      </li>
                      <li>
                        <span>Drive:</span>4x4
                      </li>
                      <li>
                        <span>Type:</span>Hatchback
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="d-price">
                Daily rate from <span>$265</span>
                <a className="btn-main" href="car-single.html">
                  Rent Now
                </a>
              </div>
              <div className="clearfix" />
            </div>
            <div className="de-item-list no-border mb30">
              <div className="d-img">
                <img
                  src="images/cars/bmw-m5.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="d-info">
                <div className="d-text">
                  <h4>BMW M2</h4>
                  <div className="d-atr-group">
                    <ul className="d-atr">
                      <li>
                        <span>Seats:</span>4
                      </li>
                      <li>
                        <span>Luggage:</span>2
                      </li>
                      <li>
                        <span>Doors:</span>4
                      </li>
                      <li>
                        <span>Fuel:</span>Petrol
                      </li>
                      <li>
                        <span>Horsepower:</span>500
                      </li>
                      <li>
                        <span>Engine:</span>3000
                      </li>
                      <li>
                        <span>Drive:</span>4x4
                      </li>
                      <li>
                        <span>Type:</span>Hatchback
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="d-price">
                Daily rate from <span>$244</span>
                <a className="btn-main" href="car-single.html">
                  Rent Now
                </a>
              </div>
              <div className="clearfix" />
            </div>
            <div className="de-item-list no-border mb30">
              <div className="d-img">
                <img
                  src="images/cars/ferrari-enzo.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="d-info">
                <div className="d-text">
                  <h4>Ferarri Enzo</h4>
                  <div className="d-atr-group">
                    <ul className="d-atr">
                      <li>
                        <span>Seats:</span>4
                      </li>
                      <li>
                        <span>Luggage:</span>2
                      </li>
                      <li>
                        <span>Doors:</span>4
                      </li>
                      <li>
                        <span>Fuel:</span>Petrol
                      </li>
                      <li>
                        <span>Horsepower:</span>500
                      </li>
                      <li>
                        <span>Engine:</span>3000
                      </li>
                      <li>
                        <span>Drive:</span>4x4
                      </li>
                      <li>
                        <span>Type:</span>Hatchback
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="d-price">
                Daily rate from <span>$167</span>
                <a className="btn-main" href="car-single.html">
                  Rent Now
                </a>
              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  {/* content close */}
  <Footer/>
</>

  )
}

export default AccountFavorite
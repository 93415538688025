import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function IconBox() {
    return (
        <>
        <Header/>
            {/* content begin */}
            <div className="no-bottom no-top zebra" id="content">
                <div id="top" />
                {/* section begin */}
                <section id="subheader" className="jarallax text-light">
                    <img
                        src="images/background/subheader.jpg"
                        className="jarallax-img"
                        alt=""
                    />
                    <div className="center-y relative text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h1>Icon Boxes</h1>
                                </div>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* section close */}
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <i className="fa fa-arrows-alt de-icon mb20" />
                                <h4>Responsive Layouts</h4>
                                <p>
                                    Aliquip consequat excepteur non dolor irure ad irure labore ex
                                    eiusmod est duis culpa ex ut minim ut ea.
                                </p>
                            </div>
                            <div className="col-md-3">
                                <i className="fa fa-microchip de-icon mb20" />
                                <h4>Solid Framework</h4>
                                <p>
                                    Aliquip consequat excepteur non dolor irure ad irure labore ex
                                    eiusmod est duis culpa ex ut minim ut ea.
                                </p>
                            </div>
                            <div className="col-md-3">
                                <i className="fa fa-trophy de-icon mb20" />
                                <h4>Top Selling Product</h4>
                                <p>
                                    Aliquip consequat excepteur non dolor irure ad irure labore ex
                                    eiusmod est duis culpa ex ut minim ut ea.
                                </p>
                            </div>
                            <div className="col-md-3">
                                <i className="fa fa-paint-brush de-icon mb20" />
                                <h4>Easy to Customize</h4>
                                <p>
                                    Aliquip consequat excepteur non dolor irure ad irure labore ex
                                    eiusmod est duis culpa ex ut minim ut ea.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="de-icon-box">
                                    <i className="fa fa-arrows-alt de-icon mb20" />
                                    <h4>Responsive Layouts</h4>
                                    <p className="mb-3">
                                        Aliquip consequat excepteur non dolor irure ad irure labore ex
                                        eiusmod est duis culpa ex ut minim ut ea.
                                    </p>
                                    <a className="btn-main btn-fullwidth" href="#">
                                        Read more
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="de-icon-box">
                                    <i className="fa fa-microchip de-icon d-circle mb20" />
                                    <h4>Solid Framework</h4>
                                    <p className="mb-3">
                                        Aliquip consequat excepteur non dolor irure ad irure labore ex
                                        eiusmod est duis culpa ex ut minim ut ea.
                                    </p>
                                    <a className="btn-main btn-fullwidth" href="#">
                                        Read more
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="de-icon-box">
                                    <i className="fa fa-trophy de-icon mb20" />
                                    <h4>Top Selling Product</h4>
                                    <p className="mb-3">
                                        Aliquip consequat excepteur non dolor irure ad irure labore ex
                                        eiusmod est duis culpa ex ut minim ut ea.
                                    </p>
                                    <a className="btn-main btn-fullwidth" href="#">
                                        Read more
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="de-icon-box">
                                    <i className="fa fa-paint-brush de-icon d-circle mb20" />
                                    <h4>Easy to Customize</h4>
                                    <p className="mb-3">
                                        Aliquip consequat excepteur non dolor irure ad irure labore ex
                                        eiusmod est duis culpa ex ut minim ut ea.
                                    </p>
                                    <a className="btn-main btn-fullwidth" href="#">
                                        Read more
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="de-icon-box bg-color-secondary text-light">
                                    <i className="fa fa-arrows-alt de-icon mb20" />
                                    <h4>Responsive Layouts</h4>
                                    <p className="mb-3">
                                        Aliquip consequat excepteur non dolor irure ad irure labore ex
                                        eiusmod est duis culpa ex ut minim ut ea.
                                    </p>
                                    <a className="btn-main btn-fullwidth btn-white" href="#">
                                        Read more
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="de-icon-box bg-color text-light">
                                    <i className="fa fa-microchip de-icon d-circle bg-color-secondary mb20" />
                                    <h4>Solid Framework</h4>
                                    <p className="mb-3">
                                        Aliquip consequat excepteur non dolor irure ad irure labore ex
                                        eiusmod est duis culpa ex ut minim ut ea.
                                    </p>
                                    <a className="btn-main btn-fullwidth btn-white" href="#">
                                        Read more
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="de-icon-box bg-color-secondary text-light">
                                    <i className="fa fa-trophy de-icon mb20" />
                                    <h4>Top Selling Product</h4>
                                    <p className="mb-3">
                                        Aliquip consequat excepteur non dolor irure ad irure labore ex
                                        eiusmod est duis culpa ex ut minim ut ea.
                                    </p>
                                    <a className="btn-main btn-fullwidth btn-white" href="#">
                                        Read more
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="de-icon-box bg-color text-light">
                                    <i className="fa fa-paint-brush de-icon bg-color-secondary d-circle mb20" />
                                    <h4>Easy to Customize</h4>
                                    <p className="mb-3">
                                        Aliquip consequat excepteur non dolor irure ad irure labore ex
                                        eiusmod est duis culpa ex ut minim ut ea.
                                    </p>
                                    <a className="btn-main btn-fullwidth btn-white" href="#">
                                        Read more
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 wow fadeInRight" data-wow-delay=".2s">
                                <div className="feature-box style-4 text-center">
                                    <a href="#">
                                        <i className="bg-color text-light i-boxed fa fa-arrows-alt" />
                                    </a>
                                    <div className="text">
                                        <a href="#">
                                            <h4>Responsive Layouts</h4>
                                        </a>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore
                                    </div>
                                    <span className="wm">1</span>
                                </div>
                            </div>
                            <div className="col-md-3 wow fadeInRight" data-wow-delay=".4s">
                                <div className="feature-box style-4 text-center">
                                    <a href="#">
                                        <i className="bg-color text-light i-boxed fa fa-microchip" />
                                    </a>
                                    <div className="text">
                                        <a href="#">
                                            <h4>Solid Framework</h4>
                                        </a>
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat nulla
                                    </div>
                                    <span className="wm">2</span>
                                </div>
                            </div>
                            <div className="col-md-3 wow fadeInRight" data-wow-delay=".6s">
                                <div className="feature-box style-4 text-center">
                                    <a href="#">
                                        <i className="bg-color text-light i-boxed fa fa-trophy" />
                                    </a>
                                    <div className="text">
                                        <a href="#">
                                            <h4>Top Selling Product</h4>
                                        </a>
                                        Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                        laboris nisi ut aliquip ex ea commodo
                                    </div>
                                    <span className="wm">3</span>
                                </div>
                            </div>
                            <div className="col-md-3 wow fadeInRight" data-wow-delay=".6s">
                                <div className="feature-box style-4 text-center">
                                    <a href="#">
                                        <i className="bg-color text-light i-boxed fa fa-paint-brush" />
                                    </a>
                                    <div className="text">
                                        <a href="#">
                                            <h4>Easy to Customize</h4>
                                        </a>
                                        Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                        laboris nisi ut aliquip ex ea commodo
                                    </div>
                                    <span className="wm">3</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="clearfix" />
                            <div className="col-lg-3">
                                <div
                                    className="box-icon s2 p-small mb20 wow fadeInRight"
                                    data-wow-delay=".5s"
                                >
                                    <i className="fa bg-color fa-arrows-alt" />
                                    <div className="d-inner">
                                        <h4>Respinsive Layouts</h4>
                                        Est dolore ut laboris eu enim eu veniam nostrud esse laborum
                                        duis consequat nostrud id
                                    </div>
                                </div>
                                <div
                                    className="box-icon s2 p-small mb20 wow fadeInL fadeInRight"
                                    data-wow-delay=".75s"
                                >
                                    <i className="fa bg-color fa-microchip" />
                                    <div className="d-inner">
                                        <h4>Solid Framework</h4>
                                        Est dolore ut laboris eu enim eu veniam nostrud esse laborum
                                        duis consequat nostrud id
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img
                                    src="images/misc/car.png"
                                    alt=""
                                    className="img-fluid wow fadeInUp"
                                />
                            </div>
                            <div className="col-lg-3">
                                <div
                                    className="box-icon s2 d-invert p-small mb20 wow fadeInL fadeInLeft"
                                    data-wow-delay="1s"
                                >
                                    <i className="fa bg-color fa-trophy" />
                                    <div className="d-inner">
                                        <h4>Top Selling Product</h4>
                                        Est dolore ut laboris eu enim eu veniam nostrud esse laborum
                                        duis consequat nostrud id
                                    </div>
                                </div>
                                <div
                                    className="box-icon s2 d-invert p-small mb20 wow fadeInL fadeInLeft"
                                    data-wow-delay="1.25s"
                                >
                                    <i className="fa bg-color fa-paint-brush" />
                                    <div className="d-inner">
                                        <h4>Easy to Customize</h4>
                                        Est dolore ut laboris eu enim eu veniam nostrud esse laborum
                                        duis consequat nostrud id
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row g-custom-x">
                            <div className="col-lg-4 col-md-6 mb30">
                                <div className="d-card">
                                    <i className="fa fa-arrows-alt" />
                                    <div className="text">
                                        <h4>Responsive Layouts</h4>Adipisicing sed voluptate cillum
                                        minim dolore in cillum ut culpa do proident duis eu adipisicing
                                        ex eiusmod irure.
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb30">
                                <div className="d-card">
                                    <i className="fa fa-microchip" />
                                    <div className="text">
                                        <h4 className="">Solid Framework</h4>Adipisicing sed voluptate
                                        cillum minim dolore in cillum ut culpa do proident duis eu
                                        adipisicing ex eiusmod irure.
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb30">
                                <div className="d-card">
                                    <i className="fa fa-trophy" />
                                    <div className="text">
                                        <h4>Top Selling Product</h4>Adipisicing sed voluptate cillum
                                        minim dolore in cillum ut culpa do proident duis eu adipisicing
                                        ex eiusmod irure.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* content close */}
            <Footer/>
        </>

    )
}

export default IconBox
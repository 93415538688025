import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function Popover() {
    return (
        <>
        <Header/>
            {/* content begin */}
            <div className="no-bottom no-top" id="content">
                <div id="top" />
                {/* section begin */}
                <section id="subheader" className="jarallax text-light">
                    <img
                        src="images/background/subheader.jpg"
                        className="jarallax-img"
                        alt=""
                    />
                    <div className="center-y relative text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h1>Popover</h1>
                                </div>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* section close */}
                {/* section begin */}
                <section id="section-fun-facts" className="pt60 pb60">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <button
                                    type="button"
                                    className="btn-main"
                                    data-bs-toggle="popover"
                                    title="Popover title"
                                    data-bs-content="And here's some amazing content. It's very engaging. Right?"
                                >
                                    Click to toggle popover
                                </button>
                                <div className="spacer-single" />
                                <h3>Four directions</h3>
                                <p>
                                    Four options are available: top, right, bottom, and left aligned.
                                    Directions are mirrored when using Bootstrap in RTL.
                                </p>
                                <button
                                    type="button"
                                    className="btn-main"
                                    data-bs-container="body"
                                    data-bs-toggle="popover"
                                    data-bs-placement="top"
                                    data-bs-content="Top popover"
                                >
                                    Popover on top
                                </button>
                                <button
                                    type="button"
                                    className="btn-main"
                                    data-bs-container="body"
                                    data-bs-toggle="popover"
                                    data-bs-placement="right"
                                    data-bs-content="Right popover"
                                >
                                    Popover on right
                                </button>
                                <button
                                    type="button"
                                    className="btn-main"
                                    data-bs-container="body"
                                    data-bs-toggle="popover"
                                    data-bs-placement="bottom"
                                    data-bs-content="Bottom popover"
                                >
                                    Popover on bottom
                                </button>
                                <button
                                    type="button"
                                    className="btn-main"
                                    data-bs-container="body"
                                    data-bs-toggle="popover"
                                    data-bs-placement="left"
                                    data-bs-content="Left popover"
                                >
                                    Popover on left
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section close */}
            </div>
            {/* content close */}
            <a href="#" id="back-to-top" />
            <Footer/>
        </>

    )
}

export default Popover
import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function Badges() {
    return (
        <>
        <Header/>
            {/* content begin */}
            <div className="no-bottom no-top" id="content">
                <div id="top" />
                {/* section begin */}
                <section id="subheader" className="jarallax text-light">
                    <img
                        src="images/background/subheader.jpg"
                        className="jarallax-img"
                        alt=""
                    />
                    <div className="center-y relative text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h1>Badge</h1>
                                </div>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* section close */}
                {/* section begin */}
                <section aria-label="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h3>Headings</h3>
                                <h1>
                                    Example heading <span className="badge bg-primary">New</span>
                                </h1>
                                <h2>
                                    Example heading <span className="badge bg-secondary">New</span>
                                </h2>
                                <h3>
                                    Example heading <span className="badge bg-success">New</span>
                                </h3>
                                <h4>
                                    Example heading <span className="badge bg-danger">New</span>
                                </h4>
                                <h5>
                                    Example heading <span className="badge bg-warning">New</span>
                                </h5>
                                <h6>
                                    Example heading <span className="badge bg-dark">New</span>
                                </h6>
                            </div>
                            <div className="col-lg-6">
                                <h3>Background colors</h3>
                                <span className="badge bg-primary">Primary</span>
                                <span className="badge bg-secondary">Secondary</span>
                                <span className="badge bg-success">Success</span>
                                <span className="badge bg-danger">Danger</span>
                                <span className="badge bg-warning text-dark">Warning</span>
                                <span className="badge bg-info text-dark">Info</span>
                                <span className="badge bg-light text-dark">Light</span>
                                <span className="badge bg-dark">Dark</span>
                                <div className="spacer-double" />
                                <h3>Pill badges</h3>
                                <span className="badge rounded-pill bg-primary">Primary</span>
                                <span className="badge rounded-pill bg-secondary">Secondary</span>
                                <span className="badge rounded-pill bg-success">Success</span>
                                <span className="badge rounded-pill bg-danger">Danger</span>
                                <span className="badge rounded-pill bg-warning text-dark">
                                    Warning
                                </span>
                                <span className="badge rounded-pill bg-info text-dark">Info</span>
                                <span className="badge rounded-pill bg-light text-dark">Light</span>
                                <span className="badge rounded-pill bg-dark">Dark</span>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section close */}
            </div>
            {/* content close */}
            <Footer/>
        </>

    )
}

export default Badges
import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function NewsGridLeftSidebar() {
    return (
        <>
        <Header/>
            {/* content begin */}
            <div className="no-bottom no-top" id="content">
                <div id="top" />
                {/* section begin */}
                <section id="subheader" className="jarallax text-light">
                    <img
                        src="images/background/subheader.jpg"
                        className="jarallax-img"
                        alt=""
                    />
                    <div className="center-y relative text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h1>News &amp; Updates</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section close */}
                <section id="section-hero" aria-label="section">
                    <div className="container">
                        <div className="row gx-5">
                            <div className="col-lg-4">
                                <div className="widget widget-post">
                                    <h4>Recent Posts</h4>
                                    <div className="small-border" />
                                    <ul className="de-bloglist-type-1">
                                        <li>
                                            <div className="d-image">
                                                <img src="images/news-thumbnail/pic-blog-1.jpg" alt="" />
                                            </div>
                                            <div className="d-content">
                                                <a href="#">
                                                    <h4>Enjoy Best Travel Experience</h4>
                                                </a>
                                                <div className="d-date">June 15, 2022</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-image">
                                                <img src="images/news-thumbnail/pic-blog-2.jpg" alt="" />
                                            </div>
                                            <div className="d-content">
                                                <a href="#">
                                                    <h4>The Future of Car Rent</h4>
                                                </a>
                                                <div className="d-date">June 15, 2022</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-image">
                                                <img src="images/news-thumbnail/pic-blog-3.jpg" alt="" />
                                            </div>
                                            <div className="d-content">
                                                <a href="#">
                                                    <h4>Holiday Tips For Backpacker</h4>
                                                </a>
                                                <div className="d-date">June 15, 2022</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-image">
                                                <img src="images/news-thumbnail/pic-blog-4.jpg" alt="" />
                                            </div>
                                            <div className="d-content">
                                                <a href="#">
                                                    <h4>Travel Destination For Couple</h4>
                                                </a>
                                                <div className="d-date">June 15, 2022</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="widget widget_tags">
                                    <h4>Popular Tags</h4>
                                    <div className="small-border" />
                                    <ul>
                                        <li>
                                            <a href="#link">accommodation</a>
                                        </li>
                                        <li>
                                            <a href="#link">holiday</a>
                                        </li>
                                        <li>
                                            <a href="#link">hostel</a>
                                        </li>
                                        <li>
                                            <a href="#link">hotel</a>
                                        </li>
                                        <li>
                                            <a href="#link">travel</a>
                                        </li>
                                        <li>
                                            <a href="#link">inn</a>
                                        </li>
                                        <li>
                                            <a href="#link">lodge</a>
                                        </li>
                                        <li>
                                            <a href="#link">motel</a>
                                        </li>
                                        <li>
                                            <a href="#link">resort</a>
                                        </li>
                                        <li>
                                            <a href="#link">travel</a>
                                        </li>
                                        <li>
                                            <a href="#link">tourism</a>
                                        </li>
                                        <li>
                                            <a href="#link">vacation</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="widget">
                                    <h4>Testimonials</h4>
                                    <div className="small-border" />
                                    <div
                                        className="owl-carousel owl-theme"
                                        id="testimonial-carousel-1-col"
                                    >
                                        <div className="item">
                                            <div className="de_testi type-2">
                                                <blockquote>
                                                    <h4>Excellent Service!</h4>
                                                    <p>
                                                        Great support, like i have never seen before. Thanks to
                                                        the support team, they are very helpfull. This company
                                                        provide customers great solution, that makes them best.
                                                    </p>
                                                    <div className="de_testi_by">
                                                        <img
                                                            alt=""
                                                            className="rounded-circle"
                                                            src="images/people/1.jpg"
                                                        />{" "}
                                                        <span>John, Pixar Studio</span>
                                                    </div>
                                                </blockquote>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="de_testi type-2">
                                                <blockquote>
                                                    <h4>Excellent Service!</h4>
                                                    <p>
                                                        Great support, like i have never seen before. Thanks to
                                                        the support team, they are very helpfull. This company
                                                        provide customers great solution, that makes them best.
                                                    </p>
                                                    <div className="de_testi_by">
                                                        <img
                                                            alt=""
                                                            className="rounded-circle"
                                                            src="images/people/2.jpg"
                                                        />{" "}
                                                        <span>Sarah, Microsoft</span>
                                                    </div>
                                                </blockquote>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="de_testi type-2">
                                                <blockquote>
                                                    <h4>Excellent Service!</h4>
                                                    <p>
                                                        Great support, like i have never seen before. Thanks to
                                                        the support team, they are very helpfull. This company
                                                        provide customers great solution, that makes them best.
                                                    </p>
                                                    <div className="de_testi_by">
                                                        <img
                                                            alt=""
                                                            className="rounded-circle"
                                                            src="images/people/3.jpg"
                                                        />{" "}
                                                        <span>Michael, Apple</span>
                                                    </div>
                                                </blockquote>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="de_testi type-2">
                                                <blockquote>
                                                    <h4>Excellent Service!</h4>
                                                    <p>
                                                        Great support, like i have never seen before. Thanks to
                                                        the support team, they are very helpfull. This company
                                                        provide customers great solution, that makes them best.
                                                    </p>
                                                    <div className="de_testi_by">
                                                        <img
                                                            alt=""
                                                            className="rounded-circle"
                                                            src="images/people/4.jpg"
                                                        />{" "}
                                                        <span>Thomas, Samsung</span>
                                                    </div>
                                                </blockquote>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="de_testi type-2">
                                                <blockquote>
                                                    <h4>Excellent Service!</h4>
                                                    <p>
                                                        Great support, like i have never seen before. Thanks to
                                                        the support team, they are very helpfull. This company
                                                        provide customers great solution, that makes them best.
                                                    </p>
                                                    <div className="de_testi_by">
                                                        <img
                                                            alt=""
                                                            className="rounded-circle"
                                                            src="images/people/1.jpg"
                                                        />{" "}
                                                        <span>John, Pixar Studio</span>
                                                    </div>
                                                </blockquote>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="de_testi type-2">
                                                <blockquote>
                                                    <h4>Excellent Service!</h4>
                                                    <p>
                                                        Great support, like i have never seen before. Thanks to
                                                        the support team, they are very helpfull. This company
                                                        provide customers great solution, that makes them best.
                                                    </p>
                                                    <div className="de_testi_by">
                                                        <img
                                                            alt=""
                                                            className="rounded-circle"
                                                            src="images/people/2.jpg"
                                                        />{" "}
                                                        <span>Sarah, Microsoft</span>
                                                    </div>
                                                </blockquote>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="de_testi type-2">
                                                <blockquote>
                                                    <h4>Excellent Service!</h4>
                                                    <p>
                                                        Great support, like i have never seen before. Thanks to
                                                        the support team, they are very helpfull. This company
                                                        provide customers great solution, that makes them best.
                                                    </p>
                                                    <div className="de_testi_by">
                                                        <img
                                                            alt=""
                                                            className="rounded-circle"
                                                            src="images/people/3.jpg"
                                                        />{" "}
                                                        <span>Michael, Apple</span>
                                                    </div>
                                                </blockquote>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="de_testi type-2">
                                                <blockquote>
                                                    <h4>Excellent Service!</h4>
                                                    <p>
                                                        Great support, like i have never seen before. Thanks to
                                                        the support team, they are very helpfull. This company
                                                        provide customers great solution, that makes them best.
                                                    </p>
                                                    <div className="de_testi_by">
                                                        <img
                                                            alt=""
                                                            className="rounded-circle"
                                                            src="images/people/4.jpg"
                                                        />{" "}
                                                        <span>Thomas, Samsung</span>
                                                    </div>
                                                </blockquote>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-lg-6 mb20">
                                        <div className="bloglist s2 item">
                                            <div className="post-content">
                                                <div className="post-image">
                                                    <div className="date-box">
                                                        <div className="m">20</div>
                                                        <div className="d">MAR</div>
                                                    </div>
                                                    <img
                                                        alt=""
                                                        src="images/news/pic-blog-1.jpg"
                                                        className="lazy"
                                                    />
                                                </div>
                                                <div className="post-text">
                                                    <h4>
                                                        <a href="news-single.html">
                                                            Enjoy Best Travel Experience
                                                            <span />
                                                        </a>
                                                    </h4>
                                                    <p>
                                                        Dolore officia sint incididunt non excepteur ea mollit
                                                        commodo ut enim reprehenderit cupidatat labore ad
                                                        laborum consectetur.
                                                    </p>
                                                    <a className="btn-main" href="news-single.html">
                                                        Read More
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb20">
                                        <div className="bloglist s2 item">
                                            <div className="post-content">
                                                <div className="post-image">
                                                    <div className="date-box">
                                                        <div className="m">19</div>
                                                        <div className="d">MAR</div>
                                                    </div>
                                                    <img
                                                        alt=""
                                                        src="images/news/pic-blog-2.jpg"
                                                        className="lazy"
                                                    />
                                                </div>
                                                <div className="post-text">
                                                    <h4>
                                                        <a href="news-single.html">
                                                            The Future of Car Rent
                                                            <span />
                                                        </a>
                                                    </h4>
                                                    <p>
                                                        Dolore officia sint incididunt non excepteur ea mollit
                                                        commodo ut enim reprehenderit cupidatat labore ad
                                                        laborum consectetur.
                                                    </p>
                                                    <a className="btn-main" href="news-single.html">
                                                        Read More
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb20">
                                        <div className="bloglist s2 item">
                                            <div className="post-content">
                                                <div className="post-image">
                                                    <div className="date-box">
                                                        <div className="m">18</div>
                                                        <div className="d">MAR</div>
                                                    </div>
                                                    <img
                                                        alt=""
                                                        src="images/news/pic-blog-3.jpg"
                                                        className="lazy"
                                                    />
                                                </div>
                                                <div className="post-text">
                                                    <h4>
                                                        <a href="news-single.html">
                                                            Holiday Tips For Backpacker
                                                            <span />
                                                        </a>
                                                    </h4>
                                                    <p>
                                                        Dolore officia sint incididunt non excepteur ea mollit
                                                        commodo ut enim reprehenderit cupidatat labore ad
                                                        laborum consectetur.
                                                    </p>
                                                    <a className="btn-main" href="news-single.html">
                                                        Read More
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb20">
                                        <div className="bloglist s2 item">
                                            <div className="post-content">
                                                <div className="post-image">
                                                    <div className="date-box">
                                                        <div className="m">17</div>
                                                        <div className="d">MAR</div>
                                                    </div>
                                                    <img
                                                        alt=""
                                                        src="images/news/pic-blog-4.jpg"
                                                        className="lazy"
                                                    />
                                                </div>
                                                <div className="post-text">
                                                    <h4>
                                                        <a href="news-single.html">
                                                            Travel Destination For Couple
                                                            <span />
                                                        </a>
                                                    </h4>
                                                    <p>
                                                        Dolore officia sint incididunt non excepteur ea mollit
                                                        commodo ut enim reprehenderit cupidatat labore ad
                                                        laborum consectetur.
                                                    </p>
                                                    <a className="btn-main" href="news-single.html">
                                                        Read More
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb20">
                                        <div className="bloglist s2 item">
                                            <div className="post-content">
                                                <div className="post-image">
                                                    <div className="date-box">
                                                        <div className="m">16</div>
                                                        <div className="d">MAR</div>
                                                    </div>
                                                    <img
                                                        alt=""
                                                        src="images/news/pic-blog-5.jpg"
                                                        className="lazy"
                                                    />
                                                </div>
                                                <div className="post-text">
                                                    <h4>
                                                        <a href="news-single.html">
                                                            The Essential Guide to Hotel
                                                            <span />
                                                        </a>
                                                    </h4>
                                                    <p>
                                                        Dolore officia sint incididunt non excepteur ea mollit
                                                        commodo ut enim reprehenderit cupidatat labore ad
                                                        laborum consectetur.
                                                    </p>
                                                    <a className="btn-main" href="news-single.html">
                                                        Read More
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb20">
                                        <div className="bloglist s2 item">
                                            <div className="post-content">
                                                <div className="post-image">
                                                    <div className="date-box">
                                                        <div className="m">15</div>
                                                        <div className="d">MAR</div>
                                                    </div>
                                                    <img
                                                        alt=""
                                                        src="images/news/pic-blog-6.jpg"
                                                        className="lazy"
                                                    />
                                                </div>
                                                <div className="post-text">
                                                    <h4>
                                                        <a href="news-single.html">
                                                            Travel on a Budget
                                                            <span />
                                                        </a>
                                                    </h4>
                                                    <p>
                                                        Dolore officia sint incididunt non excepteur ea mollit
                                                        commodo ut enim reprehenderit cupidatat labore ad
                                                        laborum consectetur.
                                                    </p>
                                                    <a className="btn-main" href="news-single.html">
                                                        Read More
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="spacer-single" />
                                    <div className="col-md-12">
                                        <ul className="pagination">
                                            <li>
                                                <a href="#">Prev</a>
                                            </li>
                                            <li className="active">
                                                <a href="#">1</a>
                                            </li>
                                            <li>
                                                <a href="#">2</a>
                                            </li>
                                            <li>
                                                <a href="#">3</a>
                                            </li>
                                            <li>
                                                <a href="#">4</a>
                                            </li>
                                            <li>
                                                <a href="#">5</a>
                                            </li>
                                            <li>
                                                <a href="#">Next</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* content close */}
            <Footer/>
        </>

    )
}

export default NewsGridLeftSidebar
import React from 'react'

function Header() {
  return (
    <>
        {/* page preloader begin */}
        {/* <div id="de-preloader" /> */}
    {/* page preloader close */}
    {/* header begin */}
    <header className="transparent scroll-light has-topbar">
      <div id="topbar" className="topbar-dark text-light">
        <div className="container">
          <div className="topbar-left xs-hide">
            <div className="topbar-widget">
              <div className="topbar-widget">
                <a href="#">
                  <i className="fa fa-phone" />
                  +208 333 9296
                </a>
              </div>
              <div className="topbar-widget">
                <a href="#">
                  <i className="fa fa-envelope" />
                  contact@rentaly.com
                </a>
              </div>
              <div className="topbar-widget">
                <a href="#">
                  <i className="fa fa-clock-o" />
                  Mon - Fri 08.00 - 18.00
                </a>
              </div>
            </div>
          </div>
          <div className="topbar-right">
            <div className="social-icons">
              <a href="#">
                <i className="fa fa-facebook fa-lg" />
              </a>
              <a href="#">
                <i className="fa fa-twitter fa-lg" />
              </a>
              <a href="#">
                <i className="fa fa-youtube fa-lg" />
              </a>
              <a href="#">
                <i className="fa fa-pinterest fa-lg" />
              </a>
              <a href="#">
                <i className="fa fa-instagram fa-lg" />
              </a>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="de-flex sm-pt10">
              <div className="de-flex-col">
                <div className="de-flex-col">
                  {/* logo begin */}
                  <div id="logo">
                    <a href="/">
                      <img
                        className="logo-1"
                        src="images/logo-light.png"
                        alt=""
                      />
                      <img className="logo-2" src="images/logo.png" alt="" />
                    </a>
                  </div>
                  {/* logo close */}
                </div>
              </div>
              <div className="de-flex-col header-col-mid">
                <ul id="mainmenu">
                  <li>
                    <a className="menu-item" href="/">
                      Home
                    </a>
                    <ul>
                      <li>
                        <a className="menu-item" href="/DarkHome">
                          New: Homepage 1 Dark
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/DarkHome1">
                          New: Homepage 2 Dark
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/">
                          Homepage Main
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/Home1">
                          Homepage 1
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/Home2">
                          Homepage 2
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/Home3">
                          Homepage 3
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/Home4">
                          Homepage 4
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/Home5">
                          Homepage 5
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="menu-item" href="/Cars">
                      Cars
                    </a>
                    <ul>
                      <li>
                        <a className="menu-item" href="/Cars">
                          Cars List 1
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/Darkcars">
                          Cars List 1 Dark
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/CarslistDark">
                          Cars List 2
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/Darkcarslist">
                          Cars List 2 Dark
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/Carsingle">
                          Cars Single
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/DarkCarsingle">
                          Cars Single Dark
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="menu-item" href="/Booking">
                      Booking
                    </a>
                  </li>
                  <li>
                    <a className="menu-item" href="account-dashboard.html">
                      My Account
                    </a>
                    <ul>
                      <li>
                        <a className="menu-item" href="/AccountDashboard">
                          Dashboard
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/AccountProfile">
                          My Profile
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/AccountBooking">
                          My Orders
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/AccountFavorite">
                          My Favorite Cars
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="menu-item" href="#">
                      Pages
                    </a>
                    <ul>
                      <li>
                        <a className="menu-item" href="/About">
                          About Us
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/Contact">
                          Contact
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/Login">
                          Login
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/Register">
                          Register
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/Error">
                          Page 404
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="menu-item" href="#">
                      News
                    </a>
                    <ul>
                      <li>
                        <a
                          className="menu-item"
                          href="/NewsStandartRightSidebar"
                        >
                          News Standard
                        </a>
                        <ul>
                          <li>
                            <a
                              className="menu-item"
                              href="/NewsStandartRightSidebar"
                            >
                              Right Sidebar
                            </a>
                          </li>
                          <li>
                            <a
                              className="menu-item"
                              href="/NewsStandartLeftSidebar"
                            >
                              Left Sidebar
                            </a>
                          </li>
                          <li>
                            <a
                              className="menu-item"
                              href="/NewsStandartNoSidebar"
                            >
                              No Sidebar
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a
                          className="menu-item"
                          href="news-grid-right-sidebar.html"
                        >
                          News Grid
                        </a>
                        <ul>
                          <li>
                            <a
                              className="menu-item"
                              href="/NewsGridRightSidebar"
                            >
                              Right Sidebar
                            </a>
                          </li>
                          <li>
                            <a
                              className="menu-item"
                              href="/NewsGridLeftSidebar"
                            >
                              Left Sidebar
                            </a>
                          </li>
                          <li>
                            <a
                              className="menu-item"
                              href="/NoSidebar"
                            >
                              No Sidebar
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="menu-item" href="#">
                      Elements
                    </a>
                    <ul>
                      <li>
                        <a className="menu-item" href="/Preloader">
                          Preloader
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/IconBox">
                          Icon Boxes
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/Badges">
                          Badge
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/Counters">
                          Counters
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/GalleryPopup">
                          Gallery Popup
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/IconsElegant">
                          Icons Elegant
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/IconsEtline">
                          Icons Etline
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/FontAwesomeIcons">
                          Icons Font Awesome
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/Map">
                          Map
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/Model">
                          Modal
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/Popover">
                          Popover
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/Tabs">
                          Tabs
                        </a>
                      </li>
                      <li>
                        <a className="menu-item" href="/Tooltips">
                          Tooltips
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="de-flex-col">
                <div className="menu_side_area">
                  <a href="/Login" className="btn-main">
                    Sign In
                  </a>
                  <span id="menu-btn" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    {/* header close */}</>
  )
}

export default Header
import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function Map() {
    return (
        <>
        <Header/>
            {/* content begin */}
            <div className="no-bottom no-top zebra" id="content">
                <div id="top" />
                {/* section begin */}
                <section id="subheader" className="jarallax text-light">
                    <img
                        src="images/background/subheader.jpg"
                        className="jarallax-img"
                        alt=""
                    />
                    <div className="center-y relative text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h1>Map</h1>
                                </div>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* section close */}
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 wow fadeInUp">
                                <div className="p-sm-30 pb-sm-0 mb-sm-0">
                                    <div className="de-map-hotspot">
                                        <div
                                            className="de-spot wow fadeIn"
                                            style={{ top: "39%", left: "20%" }}
                                        >
                                            <span>United&nbsp;States</span>
                                            <div className="de-circle-1" />
                                            <div className="de-circle-2" />
                                        </div>
                                        <div
                                            className="de-spot wow fadeIn"
                                            style={{ top: "76%", left: "87%" }}
                                        >
                                            <span>Australia</span>
                                            <div className="de-circle-1" />
                                            <div className="de-circle-2" />
                                        </div>
                                        <div
                                            className="de-spot wow fadeIn"
                                            style={{ top: "68%", left: "80%" }}
                                        >
                                            <span>Indonesia</span>
                                            <div className="de-circle-1" />
                                            <div className="de-circle-2" />
                                        </div>
                                        <div
                                            className="de-spot wow fadeIn"
                                            style={{ top: "23%", left: "18%" }}
                                        >
                                            <span>Canada</span>
                                            <div className="de-circle-1" />
                                            <div className="de-circle-2" />
                                        </div>
                                        <div
                                            className="de-spot wow fadeIn"
                                            style={{ top: "68%", left: "33%" }}
                                        >
                                            <span>Brazil</span>
                                            <div className="de-circle-1" />
                                            <div className="de-circle-2" />
                                        </div>
                                        <div
                                            className="de-spot wow fadeIn"
                                            style={{ top: "45%", left: "75%" }}
                                        >
                                            <span>China</span>
                                            <div className="de-circle-1" />
                                            <div className="de-circle-2" />
                                        </div>
                                        <div
                                            className="de-spot wow fadeIn"
                                            style={{ top: "36%", left: "48%" }}
                                        >
                                            <span>France</span>
                                            <div className="de-circle-1" />
                                            <div className="de-circle-2" />
                                        </div>
                                        <div
                                            className="de-spot wow fadeIn"
                                            style={{ top: "23%", left: "51%" }}
                                        >
                                            <span>Sweden</span>
                                            <div className="de-circle-1" />
                                            <div className="de-circle-2" />
                                        </div>
                                        <div
                                            className="de-spot wow fadeIn"
                                            style={{ top: "78%", left: "53%" }}
                                        >
                                            <span>South&nbsp;Africa</span>
                                            <div className="de-circle-1" />
                                            <div className="de-circle-2" />
                                        </div>
                                        <img src="images/misc/map.png" className="img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* content close */}
            <a href="#" id="back-to-top" />
            <Footer/>
        </>

    )
}

export default Map
import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function Tooltips() {
    return (
        <>
        <Header/>
            {/* content begin */}
            <div className="no-bottom no-top" id="content">
                <div id="top" />
                {/* section begin */}
                <section id="subheader" className="jarallax text-light">
                    <img
                        src="images/background/subheader.jpg"
                        className="jarallax-img"
                        alt=""
                    />
                    <div className="center-y relative text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h1>Tooltips</h1>
                                </div>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* section close */}
                {/* section begin */}
                <section id="section-fun-facts" className="pt60 pb60">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                Placeholder text to demonstrate some{" "}
                                <a href="#" data-bs-toggle="tooltip" title="Default tooltip">
                                    inline links
                                </a>{" "}
                                with tooltips. This is now just filler, no killer. Content placed
                                here just to mimic the presence of{" "}
                                <a href="#" data-bs-toggle="tooltip" title="Another tooltip">
                                    real text
                                </a>
                                . And all that just to give you an idea of how tooltips would look
                                when used in real-world situations. So hopefully you've now seen how{" "}
                                <a href="#" data-bs-toggle="tooltip" title="Another one here too">
                                    these tooltips on links
                                </a>{" "}
                                can work in practice, once you use them on{" "}
                                <a href="#" data-bs-toggle="tooltip" title="The last tip!">
                                    your own
                                </a>{" "}
                                site or project.
                                <div className="spacer-single" />
                                <button
                                    type="button"
                                    className="btn-main"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Tooltip on top"
                                >
                                    Tooltip on top
                                </button>
                                <button 
                                    type="button"
                                    className="btn-main"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="right"
                                    title="Tooltip on right"
                                >
                                    Tooltip on right
                                </button>
                                <button 
                                      type="button" 
                                      class="btn-main" 
                                      data-bs-toggle="tooltip" 
                                      data-bs-placement="top" 
                                      title="" 
                                      data-bs-original-title="Tooltip on top">
                                  Tooltip on top
                                </button>
                                <button
                                    type="button"
                                    className="btn-main"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    title="Tooltip on bottom"
                                >
                                    Tooltip on bottom
                                </button>
                                <button
                                    type="button"
                                    className="btn-main"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="left"
                                    title="Tooltip on left"
                                >
                                    Tooltip on left
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section close */}
            </div>
            {/* content close */}
            <a href="#" id="back-to-top" />
            <Footer/>
        </>

    )
}

export default Tooltips
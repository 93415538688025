import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function About() {
  return (
    <>
    <Header/>
  {/* content begin */}
  <div className="no-bottom no-top zebra" id="content">
    <div id="top" />
    {/* section begin */}
    <section id="subheader" className="jarallax text-light">
      <img
        src="images/background/subheader.jpg"
        className="jarallax-img"
        alt=""
      />
      <div className="center-y relative text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>About Us</h1>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </div>
    </section>
    {/* section close */}
    <section>
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-6 wow fadeInRight">
            <h2>
              We offer customers a wide range of{" "}
              <span className="id-color">commercial cars</span> and{" "}
              <span className="id-color">luxury cars</span> for any occasion.
            </h2>
          </div>
          <div className="col-lg-6 wow fadeInRight" data-wow-delay=".25s">
            At Rentaly, we are dedicated to providing exceptional car rental
            services to our valued customers. With a commitment to quality,
            convenience, and customer satisfaction, we strive to make every
            rental experience a seamless and enjoyable one. We understand that
            every customer has unique needs and preferences when it comes to
            renting a car. That's why we maintain a diverse fleet of
            well-maintained vehicles to cater to various requirements. From
            compact cars for solo travelers to spacious SUVs for families, we
            have a wide range of options to choose from.
          </div>
        </div>
        <div className="spacer-double" />
        <div className="row text-center">
          <div className="col-md-3 col-sm-6 mb-sm-30">
            <div className="de_count wow fadeInUp" data-bgcolor="#f5f5f5">
              <h3 className="timer" data-to={15425} data-speed={3000}>
                0
              </h3>
              Completed Orders
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-sm-30">
            <div className="de_count wow fadeInUp" data-bgcolor="#f5f5f5">
              <h3 className="timer" data-to={8745} data-speed={3000}>
                0
              </h3>
              Happy Customers
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-sm-30">
            <div className="de_count wow fadeInUp" data-bgcolor="#f5f5f5">
              <h3 className="timer" data-to={235} data-speed={3000}>
                0
              </h3>
              Vehicles Fleet
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-sm-30">
            <div className="de_count wow fadeInUp" data-bgcolor="#f5f5f5">
              <h3 className="timer" data-to={15} data-speed={3000}>
                0
              </h3>
              Years Experience
            </div>
          </div>
        </div>
      </div>
    </section>
    <section aria-label="section" className="jarallax text-light">
      <img src="images/background/8.jpg" className="jarallax-img" alt="" />
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h2>Board of Directors</h2>
            <div className="spacer-20" />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 mb30">
            <div className="f-profile text-center">
              <div className="fp-wrap f-invert">
                <div className="fpw-overlay">
                  <div className="fpwo-wrap">
                    <div className="fpwow-icons">
                      <a href="#">
                        <i className="fa fa-facebook fa-lg" />
                      </a>
                      <a href="#">
                        <i className="fa fa-twitter fa-lg" />
                      </a>
                      <a href="#">
                        <i className="fa fa-linkedin fa-lg" />
                      </a>
                      <a href="#">
                        <i className="fa fa-pinterest fa-lg" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="fpw-overlay-btm" />
                <img
                  src="images/team/1.jpg"
                  className="fp-image img-fluid"
                  alt=""
                />
              </div>
              <h4>Fynley Wilkinson</h4>
              Chief Creative Officer
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 mb30">
            <div className="f-profile text-center">
              <div className="fp-wrap f-invert">
                <div className="fpw-overlay">
                  <div className="fpwo-wrap">
                    <div className="fpwow-icons">
                      <a href="#">
                        <i className="fa fa-facebook fa-lg" />
                      </a>
                      <a href="#">
                        <i className="fa fa-twitter fa-lg" />
                      </a>
                      <a href="#">
                        <i className="fa fa-linkedin fa-lg" />
                      </a>
                      <a href="#">
                        <i className="fa fa-pinterest fa-lg" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="fpw-overlay-btm" />
                <img
                  src="images/team/2.jpg"
                  className="fp-image img-fluid"
                  alt=""
                />
              </div>
              <h4>Peter Welsh</h4>
              Chief Technology Officer
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 mb30">
            <div className="f-profile text-center">
              <div className="fp-wrap f-invert">
                <div className="fpw-overlay">
                  <div className="fpwo-wrap">
                    <div className="fpwow-icons">
                      <a href="#">
                        <i className="fa fa-facebook fa-lg" />
                      </a>
                      <a href="#">
                        <i className="fa fa-twitter fa-lg" />
                      </a>
                      <a href="#">
                        <i className="fa fa-linkedin fa-lg" />
                      </a>
                      <a href="#">
                        <i className="fa fa-pinterest fa-lg" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="fpw-overlay-btm" />
                <img
                  src="images/team/3.jpg"
                  className="fp-image img-fluid"
                  alt=""
                />
              </div>
              <h4>John Shepard</h4>
              Chief Executive Officer
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 mb30">
            <div className="f-profile text-center">
              <div className="fp-wrap f-invert">
                <div className="fpw-overlay">
                  <div className="fpwo-wrap">
                    <div className="fpwow-icons">
                      <a href="#">
                        <i className="fa fa-facebook fa-lg" />
                      </a>
                      <a href="#">
                        <i className="fa fa-twitter fa-lg" />
                      </a>
                      <a href="#">
                        <i className="fa fa-linkedin fa-lg" />
                      </a>
                      <a href="#">
                        <i className="fa fa-pinterest fa-lg" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="fpw-overlay-btm" />
                <img
                  src="images/team/4.jpg"
                  className="fp-image img-fluid"
                  alt=""
                />
              </div>
              <h4>Robyn Peel</h4>
              Director of Finance
            </div>
          </div>
        </div>
      </div>
    </section>
    <section aria-label="section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 offset-lg-3 text-center">
            <h2>Features Hilight</h2>
            <div className="spacer-20" />
          </div>
          <div className="clearfix" />
          <div className="col-lg-3">
            <div
              className="box-icon s2 p-small mb20 wow fadeInRight"
              data-wow-delay=".5s"
            >
              <i className="fa bg-color fa-trophy" />
              <div className="d-inner">
                <h4>First class services</h4>
                Where luxury meets exceptional care, creating unforgettable
                moments and exceeding your every expectation.
              </div>
            </div>
            <div
              className="box-icon s2 p-small mb20 wow fadeInL fadeInRight"
              data-wow-delay=".75s"
            >
              <i className="fa bg-color fa-road" />
              <div className="d-inner">
                <h4>24/7 road assistance</h4>
                Reliable support when you need it most, keeping you on the move
                with confidence and peace of mind.
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <img
              src="images/misc/car.png"
              alt=""
              className="img-fluid wow fadeInUp"
            />
          </div>
          <div className="col-lg-3">
            <div
              className="box-icon s2 d-invert p-small mb20 wow fadeInL fadeInLeft"
              data-wow-delay="1s"
            >
              <i className="fa bg-color fa-tag" />
              <div className="d-inner">
                <h4>Quality at Minimum Expense</h4>
                Unlocking affordable brilliance with elevating quality while
                minimizing costs for maximum value.
              </div>
            </div>
            <div
              className="box-icon s2 d-invert p-small mb20 wow fadeInL fadeInLeft"
              data-wow-delay="1.25s"
            >
              <i className="fa bg-color fa-map-pin" />
              <div className="d-inner">
                <h4>Free Pick-Up &amp; Drop-Off</h4>
                Enjoy free pickup and drop-off services, adding an extra layer
                of ease to your car rental experience.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="section-img-with-tab">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 offset-lg-7">
            <h2>Only Quality For Clients</h2>
            <div className="spacer-20" />
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Luxury
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Comfort
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-contact-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-contact"
                  type="button"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                >
                  Prestige
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <p>
                  We offer a meticulously curated collection of the most
                  sought-after luxury vehicles on the market. Whether you prefer
                  the sporty allure of a high-performance sports car, the
                  sophistication of a sleek and luxurious sedan, or the
                  versatility of a premium SUV, we have the perfect car to match
                  your discerning taste.
                </p>
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <p>
                  We prioritize your comfort and convenience throughout your
                  journey. We understand that a comfortable ride can make a
                  world of difference, whether you're embarking on a business
                  trip or enjoying a leisurely vacation. That's why we offer a
                  wide range of well-maintained, comfortable cars that cater to
                  your specific needs.
                </p>
              </div>
              <div
                className="tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                <p>
                  We understand that prestige goes beyond luxury. It's about
                  making a statement, embracing sophistication, and indulging in
                  the finer things in life. That's why we offer an exclusive
                  selection of prestigious cars that exude elegance, style, and
                  status.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="image-container col-md-6 pull-right"
        data-bgimage="url(images/background/5.jpg) center"
      />
    </section>
    <section
      id="section-call-to-action"
      className="bg-color-2 pt60 pb60 text-light"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8">
            <h2 className="s2">
              Call us for further information. Rentaly customer care is here to
              help you anytime.
            </h2>
          </div>
          <div className="col-lg-4 text-lg-center text-sm-center">
            <div className="phone-num-big">
              <i className="fa fa-phone" />
              <span className="pnb-text">Call Us Now</span>
              <span className="pnb-num">1 200 333 800</span>
            </div>
            <a href="#" className="btn-main">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
  {/* content close */}
  <Footer/>
</>

  )
}

export default About
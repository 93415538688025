import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
// import Marquee from '../Components/Marquee'
import Infinitemarquee from '../Components/Infinitemarquee'

function Home4() {
  return (
    <>
    <Header/>
  {/* content begin */}
  <div className="no-bottom no-top" id="content">
    <div id="top" />
    <section
      id="section-hero"
      aria-label="section"
      className="jarallax text-light full-height vertical-center"
    >
      <img src="images/background/6.jpg" className="jarallax-img" alt="" />
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="spacer-double" />
            <div className="spacer-10" />
            <h1>Explore the world with comfortable car</h1>
            <p className="lead">
              Embark on unforgettable adventures and discover the world in
              unparalleled comfort and style with our fleet of exceptionally
              comfortable cars.
            </p>
            <div className="row">
              <div className="col-lg-6">
                <div className="box-icon s2 p-small mb20">
                  <i className="fa bg-color fa-trophy" />
                  <div className="d-inner">
                    <h4 className="id-color">First class services</h4>
                    Est dolore ut laboris eu enim eu veniam nostrud esse laborum
                    duis
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="box-icon s2 p-small mb20">
                  <i className="fa bg-color fa-road" />
                  <div className="d-inner">
                    <h4 className="id-color">24/7 road assistance</h4>
                    Est dolore ut laboris eu enim eu veniam nostrud esse laborum
                    duis
                  </div>
                </div>
              </div>
            </div>
            <a className="btn-main btn-main" href="#">
              Choose a Car
            </a>
          </div>
        </div>
      </div>
    </section>
    <section id="section-cars">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 offset-lg-3 text-center">
            <h2>Our Vehicle Fleet</h2>
            <p>
              Driving your dreams to reality with an exquisite fleet of
              versatile vehicles for unforgettable journeys.
            </p>
            <div className="spacer-20" />
          </div>
          <div className="clearfix" />
          <div id="items-carousel" className="owl-carousel wow fadeIn">
            <div className="col-lg-12">
              <div className="de-item mb30">
                <div className="d-img">
                  <img
                    src="images/cars/jeep-renegade.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="d-info">
                  <div className="d-text">
                    <h4>Jeep Renegade</h4>
                    <div className="d-item_like">
                      <i className="fa fa-heart" />
                      <span>74</span>
                    </div>
                    <div className="d-atr-group">
                      <span className="d-atr">
                        <img src="images/icons/1-green.svg" alt="" />5
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/2-green.svg" alt="" />2
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/3-green.svg" alt="" />4
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/4-green.svg" alt="" />
                        SUV
                      </span>
                    </div>
                    <div className="d-price">
                      Daily rate from <span>$265</span>
                      <a className="btn-main" href="car-single.html">
                        Rent Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="de-item mb30">
                <div className="d-img">
                  <img
                    src="images/cars/bmw-m5.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="d-info">
                  <div className="d-text">
                    <h4>BMW M2</h4>
                    <div className="d-item_like">
                      <i className="fa fa-heart" />
                      <span>36</span>
                    </div>
                    <div className="d-atr-group">
                      <span className="d-atr">
                        <img src="images/icons/1-green.svg" alt="" />5
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/2-green.svg" alt="" />2
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/3-green.svg" alt="" />4
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/4-green.svg" alt="" />
                        Sedan
                      </span>
                    </div>
                    <div className="d-price">
                      Daily rate from <span>$244</span>
                      <a className="btn-main" href="car-single.html">
                        Rent Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="de-item mb30">
                <div className="d-img">
                  <img
                    src="images/cars/ferrari-enzo.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="d-info">
                  <div className="d-text">
                    <h4>Ferarri Enzo</h4>
                    <div className="d-item_like">
                      <i className="fa fa-heart" />
                      <span>85</span>
                    </div>
                    <div className="d-atr-group">
                      <span className="d-atr">
                        <img src="images/icons/1-green.svg" alt="" />5
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/2-green.svg" alt="" />2
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/3-green.svg" alt="" />4
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/4-green.svg" alt="" />
                        Exotic Car
                      </span>
                    </div>
                    <div className="d-price">
                      Daily rate from <span>$167</span>
                      <a className="btn-main" href="car-single.html">
                        Rent Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="de-item mb30">
                <div className="d-img">
                  <img
                    src="images/cars/ford-raptor.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="d-info">
                  <div className="d-text">
                    <h4>Ford Raptor</h4>
                    <div className="d-item_like">
                      <i className="fa fa-heart" />
                      <span>59</span>
                    </div>
                    <div className="d-atr-group">
                      <span className="d-atr">
                        <img src="images/icons/1-green.svg" alt="" />5
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/2-green.svg" alt="" />2
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/3-green.svg" alt="" />4
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/4-green.svg" alt="" />
                        Truck
                      </span>
                    </div>
                    <div className="d-price">
                      Daily rate from <span>$147</span>
                      <a className="btn-main" href="car-single.html">
                        Rent Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="de-item mb30">
                <div className="d-img">
                  <img
                    src="images/cars/mini-cooper.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="d-info">
                  <div className="d-text">
                    <h4>Mini Cooper</h4>
                    <div className="d-item_like">
                      <i className="fa fa-heart" />
                      <span>19</span>
                    </div>
                    <div className="d-atr-group">
                      <span className="d-atr">
                        <img src="images/icons/1-green.svg" alt="" />5
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/2-green.svg" alt="" />2
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/3-green.svg" alt="" />4
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/4-green.svg" alt="" />
                        Hatchback
                      </span>
                    </div>
                    <div className="d-price">
                      Daily rate from <span>$238</span>
                      <a className="btn-main" href="car-single.html">
                        Rent Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="de-item mb30">
                <div className="d-img">
                  <img
                    src="images/cars/vw-polo.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="d-info">
                  <div className="d-text">
                    <h4>VW Polo</h4>
                    <div className="d-item_like">
                      <i className="fa fa-heart" />
                      <span>79</span>
                    </div>
                    <div className="d-atr-group">
                      <span className="d-atr">
                        <img src="images/icons/1-green.svg" alt="" />5
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/2-green.svg" alt="" />2
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/3-green.svg" alt="" />4
                      </span>
                      <span className="d-atr">
                        <img src="images/icons/4-green.svg" alt="" />
                        Hatchback
                      </span>
                    </div>
                    <div className="d-price">
                      Daily rate from <span>$106</span>
                      <a className="btn-main" href="car-single.html">
                        Rent Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="section-img-with-tab" data-bgcolor="#f8f8f8">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 offset-lg-7">
            <h2>Only Quality For Clients</h2>
            <div className="spacer-20" />
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Luxury
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Comfort
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-contact-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-contact"
                  type="button"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                >
                  Prestige
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <p>
                  We offer a meticulously curated collection of the most
                  sought-after luxury vehicles on the market. Whether you prefer
                  the sporty allure of a high-performance sports car, the
                  sophistication of a sleek and luxurious sedan, or the
                  versatility of a premium SUV, we have the perfect car to match
                  your discerning taste.
                </p>
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <p>
                  We prioritize your comfort and convenience throughout your
                  journey. We understand that a comfortable ride can make a
                  world of difference, whether you're embarking on a business
                  trip or enjoying a leisurely vacation. That's why we offer a
                  wide range of well-maintained, comfortable cars that cater to
                  your specific needs.
                </p>
              </div>
              <div
                className="tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                <p>
                  We understand that prestige goes beyond luxury. It's about
                  making a statement, embracing sophistication, and indulging in
                  the finer things in life. That's why we offer an exclusive
                  selection of prestigious cars that exude elegance, style, and
                  status.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="image-container col-md-6 pull-right"
        data-bgimage="url(images/background/5.jpg) center"
      />
    </section>
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <h2>Explore the world with comfortable car</h2>
            <div className="spacer-20" />
          </div>
          <div className="col-md-3">
            <i className="fa fa-trophy de-icon mb20" />
            <h4>First Class Services</h4>
            <p>
              Where luxury meets exceptional care, creating unforgettable
              moments and exceeding your every expectation.
            </p>
          </div>
          <div className="col-md-3">
            <i className="fa fa-road de-icon mb20" />
            <h4>24/7 road assistance</h4>
            <p>
              Reliable support when you need it most, keeping you on the move
              with confidence and peace of mind.
            </p>
          </div>
          <div className="col-md-3">
            <i className="fa fa-map-pin de-icon mb20" />
            <h4>Free Pick-Up &amp; Drop-Off</h4>
            <p>
              Enjoy free pickup and drop-off services, adding an extra layer of
              ease to your car rental experience.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="section-testimonials" className="no-top no-bottom">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="de-image-text">
              <div className="d-text">
                <div className="d-quote id-color">
                  <i className="fa fa-quote-right" />
                </div>
                <h4>Excellent Service! Car Rent Service!</h4>
                <blockquote>
                  I have been using Rentaly for my Car Rental needs for over 5
                  years now. I have never had any problems with their service.
                  Their customer support is always responsive and helpful. I
                  would recommend Rentaly to anyone looking for a reliable Car
                  Rental provider.
                  <span className="by">Stepanie Hutchkiss</span>
                </blockquote>
              </div>
              <img
                src="images/testimonial/1.jpg"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="de-image-text">
              <div className="d-text">
                <div className="d-quote id-color">
                  <i className="fa fa-quote-right" />
                </div>
                <h4>Excellent Service! Car Rent Service!</h4>
                <blockquote>
                  We have been using Rentaly for our trips needs for several
                  years now and have always been happy with their service. Their
                  customer support is Excellent Service! and they are always
                  available to help with any issues we have. Their prices are
                  also very competitive.
                  <span className="by">Jovan Reels</span>
                </blockquote>
              </div>
              <img
                src="images/testimonial/2.jpg"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="de-image-text">
              <div className="d-text">
                <div className="d-quote id-color">
                  <i className="fa fa-quote-right" />
                </div>
                <h4>Excellent Service! Car Rent Service!</h4>
                <blockquote>
                  Endorsed by industry experts, Rentaly is the Car Rental
                  solution you can trust. With years of experience in the field,
                  we provide fast, reliable and secure Car Rental services.
                  <span className="by">Kanesha Keyton</span>
                </blockquote>
              </div>
              <img
                src="images/testimonial/3.jpg"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="section-news">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 offset-lg-3 text-center">
            <h2>Latest News</h2>
            <p>
              Breaking news, fresh perspectives, and in-depth coverage - stay
              ahead with our latest news, insights, and analysis.
            </p>
            <div className="spacer-20" />
          </div>
          <div className="col-lg-4 mb10">
            <div className="bloglist s2 item">
              <div className="post-content">
                <div className="post-image">
                  <div className="date-box">
                    <div className="m">10</div>
                    <div className="d">MAR</div>
                  </div>
                  <img
                    alt=""
                    src="images/news/pic-blog-1.jpg"
                    className="lazy"
                  />
                </div>
                <div className="post-text">
                  <h4>
                    <a href="news-single.html">
                      Enjoy Best Travel Experience
                      <span />
                    </a>
                  </h4>
                  <p>
                    Dolore officia sint incididunt non excepteur ea mollit
                    commodo ut enim reprehenderit cupidatat labore ad laborum
                    consectetur.
                  </p>
                  <a className="btn-main" href="#">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb10">
            <div className="bloglist s2 item">
              <div className="post-content">
                <div className="post-image">
                  <div className="date-box">
                    <div className="m">12</div>
                    <div className="d">MAR</div>
                  </div>
                  <img
                    alt=""
                    src="images/news/pic-blog-2.jpg"
                    className="lazy"
                  />
                </div>
                <div className="post-text">
                  <h4>
                    <a href="news-single.html">
                      The Future of Car Rent
                      <span />
                    </a>
                  </h4>
                  <p>
                    Dolore officia sint incididunt non excepteur ea mollit
                    commodo ut enim reprehenderit cupidatat labore ad laborum
                    consectetur.
                  </p>
                  <a className="btn-main" href="#">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb10">
            <div className="bloglist s2 item">
              <div className="post-content">
                <div className="post-image">
                  <div className="date-box">
                    <div className="m">14</div>
                    <div className="d">MAR</div>
                  </div>
                  <img
                    alt=""
                    src="images/news/pic-blog-3.jpg"
                    className="lazy"
                  />
                </div>
                <div className="post-text">
                  <h4>
                    <a href="news-single.html">
                      Holiday Tips For Backpacker
                      <span />
                    </a>
                  </h4>
                  <p>
                    Dolore officia sint incididunt non excepteur ea mollit
                    commodo ut enim reprehenderit cupidatat labore ad laborum
                    consectetur.
                  </p>
                  <a className="btn-main" href="#">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="section-call-to-action" className="bg-color text-light">
      <div className="container">
        <div className="row g-custom-x force-text-center">
          <div className="col-md-3 col-sm-6 mb-sm-30">
            <div className="de_count wow fadeInUp">
              <h3 className="timer" data-to={15425} data-speed={3000}>
                0
              </h3>
              Completed Orders
              <p className="d-small">
                Lorem ipsum adipisicing officia in adipisicing do velit sit
                tempor ea consectetur.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-sm-30">
            <div className="de_count wow fadeInUp">
              <h3 className="timer" data-to={8745} data-speed={3000}>
                0
              </h3>
              Happy Customers
              <p className="d-small">
                Lorem ipsum adipisicing officia in adipisicing do velit sit
                tempor ea consectetur.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-sm-30">
            <div className="de_count wow fadeInUp">
              <h3 className="timer" data-to={235} data-speed={3000}>
                0
              </h3>
              Vehicles Fleet
              <p className="d-small">
                Lorem ipsum adipisicing officia in adipisicing do velit sit
                tempor ea consectetur.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-sm-30">
            <div className="de_count wow fadeInUp">
              <h3 className="timer" data-to={15} data-speed={3000}>
                0
              </h3>
              Years Experience
              <p className="d-small">
                Lorem ipsum adipisicing officia in adipisicing do velit sit
                tempor ea consectetur.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      aria-label="section"
      className="pt40 pb40 text-light"
      data-bgcolor="#181818"
    >
      {/* Marquee */}
      {/* <Marquee/> */}
      <Infinitemarquee/>
    </section>
  </div>
  {/* content close */}
  <Footer/>
</>

  )
}

export default Home4
import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function Register() {
  return (
    <>
    <Header/>
  {/* content begin */}
  <div className="no-bottom no-top" id="content">
    <div id="top" />
    {/* section begin */}
    <section id="subheader" className="jarallax text-light">
      <img
        src="images/background/subheader.jpg"
        className="jarallax-img"
        alt=""
      />
      <div className="center-y relative text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Register</h1>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </div>
    </section>
    {/* section close */}
    <section aria-label="section">
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <h3>Don't have an account? Register now.</h3>
            <p>
              Welcome to Rentaly. We're excited to have you on board. By
              creating an account with us, you'll gain access to a range of
              benefits and convenient features that will enhance your car rental
              experience.
            </p>
            <div className="spacer-10" />
            <form
              name="contactForm"
              id="contact_form"
              className="form-border"
              method="post"
              action="https://www.madebydesignesia.com/themes/rentaly/blank.php"
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="field-set">
                    <label>Name:</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-set">
                    <label>Email Address:</label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-set">
                    <label>Choose a Username:</label>
                    <input
                      type="text"
                      name="username"
                      id="username"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-set">
                    <label>Phone:</label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-set">
                    <label>Password:</label>
                    <input
                      type="text"
                      name="password"
                      id="password"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="field-set">
                    <label>Re-enter Password:</label>
                    <input
                      type="text"
                      name="re-password"
                      id="re-password"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div id="submit" className="pull-left">
                    <input
                      type="submit"
                      id="send_message"
                      defaultValue="Register Now"
                      className="btn-main color-2"
                    />
                  </div>
                  <div id="mail_success" className="success">
                    Your message has been sent successfully.
                  </div>
                  <div id="mail_fail" className="error">
                    Sorry, error occured this time sending your message.
                  </div>
                  <div className="clearfix" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
  {/* content close */}
  <Footer/>
</>

  )
}

export default Register
import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function IconsElegant() {
    return (
        <>
        <Header/>
            {/* content begin */}
            <div className="no-bottom no-top" id="content">
                <div id="top" />
                {/* section begin */}
                <section id="subheader" className="jarallax text-light">
                    <img
                        src="images/background/subheader.jpg"
                        className="jarallax-img"
                        alt=""
                    />
                    <div className="center-y relative text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h1>Elegant Icons</h1>
                                </div>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* section close */}
                {/* section begin */}
                <section aria-label="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_up" /> &nbsp;arrow_up
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_down" /> &nbsp;arrow_down
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_left" /> &nbsp;arrow_left
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_right" />{" "}
                                &nbsp;arrow_right
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_left-up" />{" "}
                                &nbsp;arrow_left-up
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_right-up" />{" "}
                                &nbsp;arrow_right-up
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_right-down" />{" "}
                                &nbsp;arrow_right-down
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_left-down" />{" "}
                                &nbsp;arrow_left-down
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow-up-down" />{" "}
                                &nbsp;arrow-up-down
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_up-down_alt" />{" "}
                                &nbsp;arrow_up-down_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_left-right_alt" />{" "}
                                &nbsp;arrow_left-right_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_left-right" />{" "}
                                &nbsp;arrow_left-right
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_expand_alt2" />{" "}
                                &nbsp;arrow_expand_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_expand_alt" />{" "}
                                &nbsp;arrow_expand_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_condense" />{" "}
                                &nbsp;arrow_condense
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_expand" />{" "}
                                &nbsp;arrow_expand
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_move" /> &nbsp;arrow_move
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot-up" />{" "}
                                &nbsp;arrow_carrot-up
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot-down" />{" "}
                                &nbsp;arrow_carrot-down
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot-left" />{" "}
                                &nbsp;arrow_carrot-left
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot-right" />{" "}
                                &nbsp;arrow_carrot-right
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot-2up" />{" "}
                                &nbsp;arrow_carrot-2up
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot-2down" />{" "}
                                &nbsp;arrow_carrot-2down
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot-2left" />{" "}
                                &nbsp;arrow_carrot-2left
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot-2right" />{" "}
                                &nbsp;arrow_carrot-2right
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot-up_alt2" />{" "}
                                &nbsp;arrow_carrot-up_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot-down_alt2" />{" "}
                                &nbsp;arrow_carrot-down_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot-left_alt2" />{" "}
                                &nbsp;arrow_carrot-left_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot-right_alt2" />{" "}
                                &nbsp;arrow_carrot-right_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot-2up_alt2" />{" "}
                                &nbsp;arrow_carrot-2up_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot-2down_alt2" />{" "}
                                &nbsp;arrow_carrot-2down_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot-2left_alt2" />{" "}
                                &nbsp;arrow_carrot-2left_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot-2right_alt2" />{" "}
                                &nbsp;arrow_carrot-2right_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_triangle-up" />{" "}
                                &nbsp;arrow_triangle-up
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_triangle-down" />{" "}
                                &nbsp;arrow_triangle-down
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_triangle-left" />{" "}
                                &nbsp;arrow_triangle-left
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_triangle-right" />{" "}
                                &nbsp;arrow_triangle-right
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_triangle-up_alt2" />{" "}
                                &nbsp;arrow_triangle-up_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_triangle-down_alt2" />{" "}
                                &nbsp;arrow_triangle-down_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_triangle-left_alt2" />{" "}
                                &nbsp;arrow_triangle-left_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_triangle-right_alt2" />{" "}
                                &nbsp;arrow_triangle-right_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_back" /> &nbsp;arrow_back
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_minus-06" />{" "}
                                &nbsp;icon_minus-06
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_plus" /> &nbsp;icon_plus
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_close" /> &nbsp;icon_close
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_check" /> &nbsp;icon_check
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_minus_alt2" />{" "}
                                &nbsp;icon_minus_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_plus_alt2" />{" "}
                                &nbsp;icon_plus_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_close_alt2" />{" "}
                                &nbsp;icon_close_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_check_alt2" />{" "}
                                &nbsp;icon_check_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_zoom-out_alt" />{" "}
                                &nbsp;icon_zoom-out_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_zoom-in_alt" />{" "}
                                &nbsp;icon_zoom-in_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_search" />{" "}
                                &nbsp;icon_search
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_box-empty" />{" "}
                                &nbsp;icon_box-empty
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_box-selected" />{" "}
                                &nbsp;icon_box-selected
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_minus-box" />{" "}
                                &nbsp;icon_minus-box
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_plus-box" />{" "}
                                &nbsp;icon_plus-box
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_box-checked" />{" "}
                                &nbsp;icon_box-checked
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_circle-empty" />{" "}
                                &nbsp;icon_circle-empty
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_circle-slelected" />{" "}
                                &nbsp;icon_circle-slelected
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_stop_alt2" />{" "}
                                &nbsp;icon_stop_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_stop" /> &nbsp;icon_stop
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_pause_alt2" />{" "}
                                &nbsp;icon_pause_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_pause" /> &nbsp;icon_pause
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_menu" /> &nbsp;icon_menu
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_menu-square_alt2" />{" "}
                                &nbsp;icon_menu-square_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_menu-circle_alt2" />{" "}
                                &nbsp;icon_menu-circle_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_ul" /> &nbsp;icon_ul
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_ol" /> &nbsp;icon_ol
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_adjust-horiz" />{" "}
                                &nbsp;icon_adjust-horiz
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_adjust-vert" />{" "}
                                &nbsp;icon_adjust-vert
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_document_alt" />{" "}
                                &nbsp;icon_document_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_documents_alt" />{" "}
                                &nbsp;icon_documents_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_pencil" />{" "}
                                &nbsp;icon_pencil
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_pencil-edit_alt" />{" "}
                                &nbsp;icon_pencil-edit_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_pencil-edit" />{" "}
                                &nbsp;icon_pencil-edit
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_folder-alt" />{" "}
                                &nbsp;icon_folder-alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_folder-open_alt" />{" "}
                                &nbsp;icon_folder-open_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_folder-add_alt" />{" "}
                                &nbsp;icon_folder-add_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_info_alt" />{" "}
                                &nbsp;icon_info_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_error-oct_alt" />{" "}
                                &nbsp;icon_error-oct_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_error-circle_alt" />{" "}
                                &nbsp;icon_error-circle_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_error-triangle_alt" />{" "}
                                &nbsp;icon_error-triangle_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_question_alt2" />{" "}
                                &nbsp;icon_question_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_question" />{" "}
                                &nbsp;icon_question
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_comment_alt" />{" "}
                                &nbsp;icon_comment_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_chat_alt" />{" "}
                                &nbsp;icon_chat_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_vol-mute_alt" />{" "}
                                &nbsp;icon_vol-mute_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_volume-low_alt" />{" "}
                                &nbsp;icon_volume-low_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_volume-high_alt" />{" "}
                                &nbsp;icon_volume-high_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_quotations" />{" "}
                                &nbsp;icon_quotations
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_quotations_alt2" />{" "}
                                &nbsp;icon_quotations_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_clock_alt" />{" "}
                                &nbsp;icon_clock_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_lock_alt" />{" "}
                                &nbsp;icon_lock_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_lock-open_alt" />{" "}
                                &nbsp;icon_lock-open_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_key_alt" />{" "}
                                &nbsp;icon_key_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_cloud_alt" />{" "}
                                &nbsp;icon_cloud_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_cloud-upload_alt" />{" "}
                                &nbsp;icon_cloud-upload_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_cloud-download_alt" />{" "}
                                &nbsp;icon_cloud-download_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_image" /> &nbsp;icon_image
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_images" />{" "}
                                &nbsp;icon_images
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_lightbulb_alt" />{" "}
                                &nbsp;icon_lightbulb_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_gift_alt" />{" "}
                                &nbsp;icon_gift_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_house_alt" />{" "}
                                &nbsp;icon_house_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_genius" />{" "}
                                &nbsp;icon_genius
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_mobile" />{" "}
                                &nbsp;icon_mobile
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_tablet" />{" "}
                                &nbsp;icon_tablet
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_laptop" />{" "}
                                &nbsp;icon_laptop
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_desktop" />{" "}
                                &nbsp;icon_desktop
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_camera_alt" />{" "}
                                &nbsp;icon_camera_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_mail_alt" />{" "}
                                &nbsp;icon_mail_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_cone_alt" />{" "}
                                &nbsp;icon_cone_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_ribbon_alt" />{" "}
                                &nbsp;icon_ribbon_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_bag_alt" />{" "}
                                &nbsp;icon_bag_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_creditcard" />{" "}
                                &nbsp;icon_creditcard
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_cart_alt" />{" "}
                                &nbsp;icon_cart_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_paperclip" />{" "}
                                &nbsp;icon_paperclip
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_tag_alt" />{" "}
                                &nbsp;icon_tag_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_tags_alt" />{" "}
                                &nbsp;icon_tags_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_trash_alt" />{" "}
                                &nbsp;icon_trash_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_cursor_alt" />{" "}
                                &nbsp;icon_cursor_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_mic_alt" />{" "}
                                &nbsp;icon_mic_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_compass_alt" />{" "}
                                &nbsp;icon_compass_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_pin_alt" />{" "}
                                &nbsp;icon_pin_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_pushpin_alt" />{" "}
                                &nbsp;icon_pushpin_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_map_alt" />{" "}
                                &nbsp;icon_map_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_drawer_alt" />{" "}
                                &nbsp;icon_drawer_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_toolbox_alt" />{" "}
                                &nbsp;icon_toolbox_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_book_alt" />{" "}
                                &nbsp;icon_book_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_calendar" />{" "}
                                &nbsp;icon_calendar
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_film" /> &nbsp;icon_film
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_table" /> &nbsp;icon_table
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_contacts_alt" />{" "}
                                &nbsp;icon_contacts_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_headphones" />{" "}
                                &nbsp;icon_headphones
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_lifesaver" />{" "}
                                &nbsp;icon_lifesaver
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_piechart" />{" "}
                                &nbsp;icon_piechart
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_refresh" />{" "}
                                &nbsp;icon_refresh
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_link_alt" />{" "}
                                &nbsp;icon_link_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_link" /> &nbsp;icon_link
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_loading" />{" "}
                                &nbsp;icon_loading
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_blocked" />{" "}
                                &nbsp;icon_blocked
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_archive_alt" />{" "}
                                &nbsp;icon_archive_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_heart_alt" />{" "}
                                &nbsp;icon_heart_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_printer" />{" "}
                                &nbsp;icon_printer
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_calulator" />{" "}
                                &nbsp;icon_calulator
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_building" />{" "}
                                &nbsp;icon_building
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_floppy" />{" "}
                                &nbsp;icon_floppy
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_drive" /> &nbsp;icon_drive
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_search-2" />{" "}
                                &nbsp;icon_search-2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_id" /> &nbsp;icon_id
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_id-2" /> &nbsp;icon_id-2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_puzzle" />{" "}
                                &nbsp;icon_puzzle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_like" /> &nbsp;icon_like
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_dislike" />{" "}
                                &nbsp;icon_dislike
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_mug" /> &nbsp;icon_mug
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_currency" />{" "}
                                &nbsp;icon_currency
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_wallet" />{" "}
                                &nbsp;icon_wallet
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_pens" /> &nbsp;icon_pens
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_easel" /> &nbsp;icon_easel
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_flowchart" />{" "}
                                &nbsp;icon_flowchart
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_datareport" />{" "}
                                &nbsp;icon_datareport
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_briefcase" />{" "}
                                &nbsp;icon_briefcase
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_shield" />{" "}
                                &nbsp;icon_shield
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_percent" />{" "}
                                &nbsp;icon_percent
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_globe" /> &nbsp;icon_globe
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_globe-2" />{" "}
                                &nbsp;icon_globe-2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_target" />{" "}
                                &nbsp;icon_target
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_hourglass" />{" "}
                                &nbsp;icon_hourglass
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_balance" />{" "}
                                &nbsp;icon_balance
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_star_alt" />{" "}
                                &nbsp;icon_star_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_star-half_alt" />{" "}
                                &nbsp;icon_star-half_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_star" /> &nbsp;icon_star
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_star-half" />{" "}
                                &nbsp;icon_star-half
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_tools" /> &nbsp;icon_tools
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_tool" /> &nbsp;icon_tool
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_cog" /> &nbsp;icon_cog
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_cogs" /> &nbsp;icon_cogs
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_up_alt" />{" "}
                                &nbsp;arrow_up_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_down_alt" />{" "}
                                &nbsp;arrow_down_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_left_alt" />{" "}
                                &nbsp;arrow_left_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_right_alt" />{" "}
                                &nbsp;arrow_right_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_left-up_alt" />{" "}
                                &nbsp;arrow_left-up_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_right-up_alt" />{" "}
                                &nbsp;arrow_right-up_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_right-down_alt" />{" "}
                                &nbsp;arrow_right-down_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_left-down_alt" />{" "}
                                &nbsp;arrow_left-down_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_condense_alt" />{" "}
                                &nbsp;arrow_condense_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_expand_alt3" />{" "}
                                &nbsp;arrow_expand_alt3
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot_up_alt" />{" "}
                                &nbsp;arrow_carrot_up_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot-down_alt" />{" "}
                                &nbsp;arrow_carrot-down_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot-left_alt" />{" "}
                                &nbsp;arrow_carrot-left_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot-right_alt" />{" "}
                                &nbsp;arrow_carrot-right_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot-2up_alt" />{" "}
                                &nbsp;arrow_carrot-2up_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot-2dwnn_alt" />{" "}
                                &nbsp;arrow_carrot-2dwnn_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot-2left_alt" />{" "}
                                &nbsp;arrow_carrot-2left_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_carrot-2right_alt" />{" "}
                                &nbsp;arrow_carrot-2right_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_triangle-up_alt" />{" "}
                                &nbsp;arrow_triangle-up_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_triangle-down_alt" />{" "}
                                &nbsp;arrow_triangle-down_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_triangle-left_alt" />{" "}
                                &nbsp;arrow_triangle-left_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="arrow_triangle-right_alt" />{" "}
                                &nbsp;arrow_triangle-right_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_minus_alt" />{" "}
                                &nbsp;icon_minus_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_plus_alt" />{" "}
                                &nbsp;icon_plus_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_close_alt" />{" "}
                                &nbsp;icon_close_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_check_alt" />{" "}
                                &nbsp;icon_check_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_zoom-out" />{" "}
                                &nbsp;icon_zoom-out
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_zoom-in" />{" "}
                                &nbsp;icon_zoom-in
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_stop_alt" />{" "}
                                &nbsp;icon_stop_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_menu-square_alt" />{" "}
                                &nbsp;icon_menu-square_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_menu-circle_alt" />{" "}
                                &nbsp;icon_menu-circle_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_document" />{" "}
                                &nbsp;icon_document
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_documents" />{" "}
                                &nbsp;icon_documents
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_pencil_alt" />{" "}
                                &nbsp;icon_pencil_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_folder" />{" "}
                                &nbsp;icon_folder
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_folder-open" />{" "}
                                &nbsp;icon_folder-open
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_folder-add" />{" "}
                                &nbsp;icon_folder-add
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_folder_upload" />{" "}
                                &nbsp;icon_folder_upload
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_folder_download" />{" "}
                                &nbsp;icon_folder_download
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_info" /> &nbsp;icon_info
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_error-circle" />{" "}
                                &nbsp;icon_error-circle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_error-oct" />{" "}
                                &nbsp;icon_error-oct
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_error-triangle" />{" "}
                                &nbsp;icon_error-triangle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_question_alt" />{" "}
                                &nbsp;icon_question_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_comment" />{" "}
                                &nbsp;icon_comment
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_chat" /> &nbsp;icon_chat
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_vol-mute" />{" "}
                                &nbsp;icon_vol-mute
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_volume-low" />{" "}
                                &nbsp;icon_volume-low
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_volume-high" />{" "}
                                &nbsp;icon_volume-high
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_quotations_alt" />{" "}
                                &nbsp;icon_quotations_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_clock" /> &nbsp;icon_clock
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_lock" /> &nbsp;icon_lock
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_lock-open" />{" "}
                                &nbsp;icon_lock-open
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_key" /> &nbsp;icon_key
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_cloud" /> &nbsp;icon_cloud
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_cloud-upload" />{" "}
                                &nbsp;icon_cloud-upload
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_cloud-download" />{" "}
                                &nbsp;icon_cloud-download
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_lightbulb" />{" "}
                                &nbsp;icon_lightbulb
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_gift" /> &nbsp;icon_gift
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_house" /> &nbsp;icon_house
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_camera" />{" "}
                                &nbsp;icon_camera
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_mail" /> &nbsp;icon_mail
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_cone" /> &nbsp;icon_cone
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_ribbon" />{" "}
                                &nbsp;icon_ribbon
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_bag" /> &nbsp;icon_bag
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_cart" /> &nbsp;icon_cart
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_tag" /> &nbsp;icon_tag
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_tags" /> &nbsp;icon_tags
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_trash" /> &nbsp;icon_trash
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_cursor" />{" "}
                                &nbsp;icon_cursor
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_mic" /> &nbsp;icon_mic
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_compass" />{" "}
                                &nbsp;icon_compass
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_pin" /> &nbsp;icon_pin
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_pushpin" />{" "}
                                &nbsp;icon_pushpin
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_map" /> &nbsp;icon_map
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_drawer" />{" "}
                                &nbsp;icon_drawer
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_toolbox" />{" "}
                                &nbsp;icon_toolbox
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_book" /> &nbsp;icon_book
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_contacts" />{" "}
                                &nbsp;icon_contacts
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_archive" />{" "}
                                &nbsp;icon_archive
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_heart" /> &nbsp;icon_heart
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_profile" />{" "}
                                &nbsp;icon_profile
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_group" /> &nbsp;icon_group
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_grid-2x2" />{" "}
                                &nbsp;icon_grid-2x2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_grid-3x3" />{" "}
                                &nbsp;icon_grid-3x3
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_music" /> &nbsp;icon_music
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_pause_alt" />{" "}
                                &nbsp;icon_pause_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_phone" /> &nbsp;icon_phone
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_upload" />{" "}
                                &nbsp;icon_upload
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_download" />{" "}
                                &nbsp;icon_download
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_rook" /> &nbsp;icon_rook
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_printer-alt" />{" "}
                                &nbsp;icon_printer-alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_calculator_alt" />{" "}
                                &nbsp;icon_calculator_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_building_alt" />{" "}
                                &nbsp;icon_building_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_floppy_alt" />{" "}
                                &nbsp;icon_floppy_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_drive_alt" />{" "}
                                &nbsp;icon_drive_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_search_alt" />{" "}
                                &nbsp;icon_search_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_id_alt" />{" "}
                                &nbsp;icon_id_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_id-2_alt" />{" "}
                                &nbsp;icon_id-2_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_puzzle_alt" />{" "}
                                &nbsp;icon_puzzle_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_like_alt" />{" "}
                                &nbsp;icon_like_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_dislike_alt" />{" "}
                                &nbsp;icon_dislike_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_mug_alt" />{" "}
                                &nbsp;icon_mug_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_currency_alt" />{" "}
                                &nbsp;icon_currency_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_wallet_alt" />{" "}
                                &nbsp;icon_wallet_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_pens_alt" />{" "}
                                &nbsp;icon_pens_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_easel_alt" />{" "}
                                &nbsp;icon_easel_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_flowchart_alt" />{" "}
                                &nbsp;icon_flowchart_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_datareport_alt" />{" "}
                                &nbsp;icon_datareport_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_briefcase_alt" />{" "}
                                &nbsp;icon_briefcase_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_shield_alt" />{" "}
                                &nbsp;icon_shield_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_percent_alt" />{" "}
                                &nbsp;icon_percent_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_globe_alt" />{" "}
                                &nbsp;icon_globe_alt
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon_clipboard" />{" "}
                                &nbsp;icon_clipboard
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_facebook" />{" "}
                                &nbsp;social_facebook
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_twitter" />{" "}
                                &nbsp;social_twitter
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_pinterest" />{" "}
                                &nbsp;social_pinterest
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_googleplus" />{" "}
                                &nbsp;social_googleplus
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_tumblr" />{" "}
                                &nbsp;social_tumblr
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_tumbleupon" />{" "}
                                &nbsp;social_tumbleupon
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_wordpress" />{" "}
                                &nbsp;social_wordpress
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_instagram" />{" "}
                                &nbsp;social_instagram
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_dribbble" />{" "}
                                &nbsp;social_dribbble
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_vimeo" />{" "}
                                &nbsp;social_vimeo
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_linkedin" />{" "}
                                &nbsp;social_linkedin
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_rss" /> &nbsp;social_rss
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_deviantart" />{" "}
                                &nbsp;social_deviantart
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_share" />{" "}
                                &nbsp;social_share
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_myspace" />{" "}
                                &nbsp;social_myspace
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_skype" />{" "}
                                &nbsp;social_skype
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_youtube" />{" "}
                                &nbsp;social_youtube
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_picassa" />{" "}
                                &nbsp;social_picassa
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_googledrive" />{" "}
                                &nbsp;social_googledrive
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_flickr" />{" "}
                                &nbsp;social_flickr
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_blogger" />{" "}
                                &nbsp;social_blogger
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_spotify" />{" "}
                                &nbsp;social_spotify
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_delicious" />{" "}
                                &nbsp;social_delicious
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_facebook_circle" />{" "}
                                &nbsp;social_facebook_circle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_twitter_circle" />{" "}
                                &nbsp;social_twitter_circle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_pinterest_circle" />{" "}
                                &nbsp;social_pinterest_circle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_googleplus_circle" />{" "}
                                &nbsp;social_googleplus_circle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_tumblr_circle" />{" "}
                                &nbsp;social_tumblr_circle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_stumbleupon_circle" />{" "}
                                &nbsp;social_stumbleupon_circle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_wordpress_circle" />{" "}
                                &nbsp;social_wordpress_circle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_instagram_circle" />{" "}
                                &nbsp;social_instagram_circle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_dribbble_circle" />{" "}
                                &nbsp;social_dribbble_circle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_vimeo_circle" />{" "}
                                &nbsp;social_vimeo_circle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_linkedin_circle" />{" "}
                                &nbsp;social_linkedin_circle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_rss_circle" />{" "}
                                &nbsp;social_rss_circle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_deviantart_circle" />{" "}
                                &nbsp;social_deviantart_circle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_share_circle" />{" "}
                                &nbsp;social_share_circle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_myspace_circle" />{" "}
                                &nbsp;social_myspace_circle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_skype_circle" />{" "}
                                &nbsp;social_skype_circle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_youtube_circle" />{" "}
                                &nbsp;social_youtube_circle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_picassa_circle" />{" "}
                                &nbsp;social_picassa_circle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_googledrive_alt2" />{" "}
                                &nbsp;social_googledrive_alt2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_flickr_circle" />{" "}
                                &nbsp;social_flickr_circle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_blogger_circle" />{" "}
                                &nbsp;social_blogger_circle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_spotify_circle" />{" "}
                                &nbsp;social_spotify_circle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_delicious_circle" />{" "}
                                &nbsp;social_delicious_circle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_facebook_square" />{" "}
                                &nbsp;social_facebook_square
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_twitter_square" />{" "}
                                &nbsp;social_twitter_square
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_pinterest_square" />{" "}
                                &nbsp;social_pinterest_square
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_googleplus_square" />{" "}
                                &nbsp;social_googleplus_square
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_tumblr_square" />{" "}
                                &nbsp;social_tumblr_square
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_stumbleupon_square" />{" "}
                                &nbsp;social_stumbleupon_square
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_wordpress_square" />{" "}
                                &nbsp;social_wordpress_square
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_instagram_square" />{" "}
                                &nbsp;social_instagram_square
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_dribbble_square" />{" "}
                                &nbsp;social_dribbble_square
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_vimeo_square" />{" "}
                                &nbsp;social_vimeo_square
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_linkedin_square" />{" "}
                                &nbsp;social_linkedin_square
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_rss_square" />{" "}
                                &nbsp;social_rss_square
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_deviantart_square" />{" "}
                                &nbsp;social_deviantart_square
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_share_square" />{" "}
                                &nbsp;social_share_square
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_myspace_square" />{" "}
                                &nbsp;social_myspace_square
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_skype_square" />{" "}
                                &nbsp;social_skype_square
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_youtube_square" />{" "}
                                &nbsp;social_youtube_square
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_picassa_square" />{" "}
                                &nbsp;social_picassa_square
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_googledrive_square" />{" "}
                                &nbsp;social_googledrive_square
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_flickr_square" />{" "}
                                &nbsp;social_flickr_square
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_blogger_square" />{" "}
                                &nbsp;social_blogger_square
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_spotify_square" />{" "}
                                &nbsp;social_spotify_square
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="social_delicious_square" />{" "}
                                &nbsp;social_delicious_square
                            </div>
                        </div>
                    </div>
                </section>
                {/* section close */}
            </div>
            {/* content close --*/}
            <Footer/>
        </>

    )
}

export default IconsElegant
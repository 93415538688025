import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function NoSidebar() {
    return (
        <>
        <Header/>
            {/* content begin */}
            <div className="no-bottom no-top" id="content">
                <div id="top" />
                {/* section begin */}
                <section id="subheader" className="jarallax text-light">
                    <img
                        src="images/background/subheader.jpg"
                        className="jarallax-img"
                        alt=""
                    />
                    <div className="center-y relative text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h1>News &amp; Updates</h1>
                                </div>
                                <div className="col-lg-6 offset-lg-3">
                                    <p className="lead">
                                        Et in deserunt sed consequat culpa laborum ex aliqua labore
                                        eiusmod eu consequat cupidatat ut reprehenderit amet magna.
                                    </p>
                                </div>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* section close */}
                <section id="section-news" className="no-top mt-50" aria-label="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mb20">
                                <div className="bloglist s2 item">
                                    <div className="post-content">
                                        <div className="post-image">
                                            <div className="date-box">
                                                <div className="m">20</div>
                                                <div className="d">MAR</div>
                                            </div>
                                            <img
                                                alt=""
                                                src="images/news/pic-blog-1.jpg"
                                                className="lazy"
                                            />
                                        </div>
                                        <div className="post-text">
                                            <h4>
                                                <a href="news-single.html">
                                                    Enjoy Best Travel Experience
                                                    <span />
                                                </a>
                                            </h4>
                                            <p>
                                                Dolore officia sint incididunt non excepteur ea mollit
                                                commodo ut enim reprehenderit cupidatat labore ad laborum
                                                consectetur.
                                            </p>
                                            <a className="btn-main" href="#">
                                                Read More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb20">
                                <div className="bloglist s2 item">
                                    <div className="post-content">
                                        <div className="post-image">
                                            <div className="date-box">
                                                <div className="m">19</div>
                                                <div className="d">MAR</div>
                                            </div>
                                            <img
                                                alt=""
                                                src="images/news/pic-blog-2.jpg"
                                                className="lazy"
                                            />
                                        </div>
                                        <div className="post-text">
                                            <h4>
                                                <a href="news-single.html">
                                                    The Future of Car Rent
                                                    <span />
                                                </a>
                                            </h4>
                                            <p>
                                                Dolore officia sint incididunt non excepteur ea mollit
                                                commodo ut enim reprehenderit cupidatat labore ad laborum
                                                consectetur.
                                            </p>
                                            <a className="btn-main" href="#">
                                                Read More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb20">
                                <div className="bloglist s2 item">
                                    <div className="post-content">
                                        <div className="post-image">
                                            <div className="date-box">
                                                <div className="m">18</div>
                                                <div className="d">MAR</div>
                                            </div>
                                            <img
                                                alt=""
                                                src="images/news/pic-blog-3.jpg"
                                                className="lazy"
                                            />
                                        </div>
                                        <div className="post-text">
                                            <h4>
                                                <a href="news-single.html">
                                                    Holiday Tips For Backpacker
                                                    <span />
                                                </a>
                                            </h4>
                                            <p>
                                                Dolore officia sint incididunt non excepteur ea mollit
                                                commodo ut enim reprehenderit cupidatat labore ad laborum
                                                consectetur.
                                            </p>
                                            <a className="btn-main" href="#">
                                                Read More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb20">
                                <div className="bloglist s2 item">
                                    <div className="post-content">
                                        <div className="post-image">
                                            <div className="date-box">
                                                <div className="m">17</div>
                                                <div className="d">MAR</div>
                                            </div>
                                            <img
                                                alt=""
                                                src="images/news/pic-blog-4.jpg"
                                                className="lazy"
                                            />
                                        </div>
                                        <div className="post-text">
                                            <h4>
                                                <a href="news-single.html">
                                                    Travel Destination For Couple
                                                    <span />
                                                </a>
                                            </h4>
                                            <p>
                                                Dolore officia sint incididunt non excepteur ea mollit
                                                commodo ut enim reprehenderit cupidatat labore ad laborum
                                                consectetur.
                                            </p>
                                            <a className="btn-main" href="#">
                                                Read More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb20">
                                <div className="bloglist s2 item">
                                    <div className="post-content">
                                        <div className="post-image">
                                            <div className="date-box">
                                                <div className="m">16</div>
                                                <div className="d">MAR</div>
                                            </div>
                                            <img
                                                alt=""
                                                src="images/news/pic-blog-5.jpg"
                                                className="lazy"
                                            />
                                        </div>
                                        <div className="post-text">
                                            <h4>
                                                <a href="news-single.html">
                                                    The Essential Guide to Hotel
                                                    <span />
                                                </a>
                                            </h4>
                                            <p>
                                                Dolore officia sint incididunt non excepteur ea mollit
                                                commodo ut enim reprehenderit cupidatat labore ad laborum
                                                consectetur.
                                            </p>
                                            <a className="btn-main" href="#">
                                                Read More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb20">
                                <div className="bloglist s2 item">
                                    <div className="post-content">
                                        <div className="post-image">
                                            <div className="date-box">
                                                <div className="m">15</div>
                                                <div className="d">MAR</div>
                                            </div>
                                            <img
                                                alt=""
                                                src="images/news/pic-blog-6.jpg"
                                                className="lazy"
                                            />
                                        </div>
                                        <div className="post-text">
                                            <h4>
                                                <a href="news-single.html">
                                                    Travel on a Budget
                                                    <span />
                                                </a>
                                            </h4>
                                            <p>
                                                Dolore officia sint incididunt non excepteur ea mollit
                                                commodo ut enim reprehenderit cupidatat labore ad laborum
                                                consectetur.
                                            </p>
                                            <a className="btn-main" href="#">
                                                Read More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="spacer-single" />
                            <div className="col-md-12">
                                <ul className="pagination">
                                    <li>
                                        <a href="#">Prev</a>
                                    </li>
                                    <li className="active">
                                        <a href="#">1</a>
                                    </li>
                                    <li>
                                        <a href="#">2</a>
                                    </li>
                                    <li>
                                        <a href="#">3</a>
                                    </li>
                                    <li>
                                        <a href="#">4</a>
                                    </li>
                                    <li>
                                        <a href="#">5</a>
                                    </li>
                                    <li>
                                        <a href="#">Next</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* content close */}
            <Footer/>
        </>

    )
}

export default NoSidebar
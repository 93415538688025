import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function Preloader() {
    return (
        <>
        <Header/>
            {/* content begin */}
            <div className="no-bottom no-top" id="content">
                <div id="top" />
                {/* section begin */}
                <section id="subheader" className="jarallax text-light">
                    <img
                        src="images/background/subheader.jpg"
                        className="jarallax-img"
                        alt=""
                    />
                    <div className="center-y relative text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h1>Preloader</h1>
                                </div>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* section close */}
                {/* section begin */}
                <section aria-label="section">
                    <div className="container">
                        <div className="row row-flex g-4">
                            <div className="col-lg-3">
                                <div className="de-icon-box p-5 text-center h-100">
                                    <img src="images/preloader/arrows.svg" className="" alt="" />
                                    <div className="spacer-20" />
                                    <h4>arrows</h4>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="de-icon-box p-5 text-center h-100">
                                    <img src="images/preloader/balls.svg" className="" alt="" />
                                    <div className="spacer-20" />
                                    <h4>balls</h4>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="de-icon-box p-5 text-center h-100">
                                    <img src="images/preloader/gear.svg" className="" alt="" />
                                    <div className="spacer-20" />
                                    <h4>gear</h4>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="de-icon-box p-5 text-center h-100">
                                    <img src="images/preloader/hatchway.svg" className="" alt="" />
                                    <div className="spacer-20" />
                                    <h4>hatchway</h4>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="de-icon-box p-5 text-center h-100">
                                    <img src="images/preloader/pacman.svg" className="" alt="" />
                                    <div className="spacer-20" />
                                    <h4>pacman</h4>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="de-icon-box p-5 text-center h-100">
                                    <img src="images/preloader/petals.svg" className="" alt="" />
                                    <div className="spacer-20" />
                                    <h4>petals</h4>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="de-icon-box p-5 text-center h-100">
                                    <img src="images/preloader/segments.svg" className="" alt="" />
                                    <div className="spacer-20" />
                                    <h4>segments</h4>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="de-icon-box p-5 text-center h-100">
                                    <img src="images/preloader/stars.svg" className="" alt="" />
                                    <div className="spacer-20" />
                                    <h4>stars</h4>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="de-icon-box p-5 text-center h-100">
                                    <img src="images/preloader/stopwatch.svg" className="" alt="" />
                                    <div className="spacer-20" />
                                    <h4>stopwatch</h4>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="de-icon-box p-5 text-center h-100">
                                    <img src="images/preloader/timer.svg" className="" alt="" />
                                    <div className="spacer-20" />
                                    <h4>timer</h4>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="de-icon-box p-5 text-center h-100">
                                    <img src="images/preloader/triplex.svg" className="" alt="" />
                                    <div className="spacer-20" />
                                    <h4>triplex</h4>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="de-icon-box p-5 text-center h-100">
                                    <img src="images/preloader/twirl.svg" className="" alt="" />
                                    <div className="spacer-20" />
                                    <h4>twirl</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section close */}
            </div>
            {/* content close */}
            <Footer/>
        </>

    )
}

export default Preloader
import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function IconsEtline() {
    return (
        <>
        <Header/>
            {/* content begin */}
            <div className="no-bottom no-top" id="content">
                <div id="top" />
                {/* section begin */}
                <section id="subheader" className="jarallax text-light">
                    <img
                        src="images/background/subheader.jpg"
                        className="jarallax-img"
                        alt=""
                    />
                    <div className="center-y relative text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h1>Etline Icons</h1>
                                </div>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* section close */}
                {/* section begin */}
                <section aria-label="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-mobile" />{" "}
                                &nbsp;icon-mobile
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-laptop" />{" "}
                                &nbsp;icon-laptop
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-desktop" />{" "}
                                &nbsp;icon-desktop
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-tablet" />{" "}
                                &nbsp;icon-tablet
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-phone" /> &nbsp;icon-phone
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-document" />{" "}
                                &nbsp;icon-document
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-documents" />{" "}
                                &nbsp;icon-documents
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-search" />{" "}
                                &nbsp;icon-search
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-clipboard" />{" "}
                                &nbsp;icon-clipboard
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-newspaper" />{" "}
                                &nbsp;icon-newspaper
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-notebook" />{" "}
                                &nbsp;icon-notebook
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-book-open" />{" "}
                                &nbsp;icon-book-open
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-browser" />{" "}
                                &nbsp;icon-browser
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-calendar" />{" "}
                                &nbsp;icon-calendar
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-presentation" />{" "}
                                &nbsp;icon-presentation
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-picture" />{" "}
                                &nbsp;icon-picture
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-pictures" />{" "}
                                &nbsp;icon-pictures
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-video" /> &nbsp;icon-video
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-camera" />{" "}
                                &nbsp;icon-camera
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-printer" />{" "}
                                &nbsp;icon-printer
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-toolbox" />{" "}
                                &nbsp;icon-toolbox
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-briefcase" />{" "}
                                &nbsp;icon-briefcase
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-wallet" />{" "}
                                &nbsp;icon-wallet
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-gift" /> &nbsp;icon-gift
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-bargraph" />{" "}
                                &nbsp;icon-bargraph
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-grid" /> &nbsp;icon-grid
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-expand" />{" "}
                                &nbsp;icon-expand
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-focus" /> &nbsp;icon-focus
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-edit" /> &nbsp;icon-edit
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-adjustments" />{" "}
                                &nbsp;icon-adjustments
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-ribbon" />{" "}
                                &nbsp;icon-ribbon
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-hourglass" />{" "}
                                &nbsp;icon-hourglass
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-lock" /> &nbsp;icon-lock
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-megaphone" />{" "}
                                &nbsp;icon-megaphone
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-shield" />{" "}
                                &nbsp;icon-shield
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-trophy" />{" "}
                                &nbsp;icon-trophy
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-flag" /> &nbsp;icon-flag
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-map" /> &nbsp;icon-map
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-puzzle" />{" "}
                                &nbsp;icon-puzzle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-basket" />{" "}
                                &nbsp;icon-basket
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-envelope" />{" "}
                                &nbsp;icon-envelope
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-streetsign" />{" "}
                                &nbsp;icon-streetsign
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-telescope" />{" "}
                                &nbsp;icon-telescope
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-gears" /> &nbsp;icon-gears
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-key" /> &nbsp;icon-key
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-paperclip" />{" "}
                                &nbsp;icon-paperclip
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-attachment" />{" "}
                                &nbsp;icon-attachment
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-pricetags" />{" "}
                                &nbsp;icon-pricetags
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-lightbulb" />{" "}
                                &nbsp;icon-lightbulb
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-layers" />{" "}
                                &nbsp;icon-layers
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-pencil" />{" "}
                                &nbsp;icon-pencil
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-tools" /> &nbsp;icon-tools
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-tools-2" />{" "}
                                &nbsp;icon-tools-2
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-scissors" />{" "}
                                &nbsp;icon-scissors
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-paintbrush" />{" "}
                                &nbsp;icon-paintbrush
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-magnifying-glass" />{" "}
                                &nbsp;icon-magnifying-glass
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-circle-compass" />{" "}
                                &nbsp;icon-circle-compass
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-linegraph" />{" "}
                                &nbsp;icon-linegraph
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-mic" /> &nbsp;icon-mic
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-strategy" />{" "}
                                &nbsp;icon-strategy
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-beaker" />{" "}
                                &nbsp;icon-beaker
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-caution" />{" "}
                                &nbsp;icon-caution
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-recycle" />{" "}
                                &nbsp;icon-recycle
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-anchor" />{" "}
                                &nbsp;icon-anchor
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-profile-male" />{" "}
                                &nbsp;icon-profile-male
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-profile-female" />{" "}
                                &nbsp;icon-profile-female
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-bike" /> &nbsp;icon-bike
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-wine" /> &nbsp;icon-wine
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-hotairballoon" />{" "}
                                &nbsp;icon-hotairballoon
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-globe" /> &nbsp;icon-globe
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-genius" />{" "}
                                &nbsp;icon-genius
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-map-pin" />{" "}
                                &nbsp;icon-map-pin
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-dial" /> &nbsp;icon-dial
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-chat" /> &nbsp;icon-chat
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-heart" /> &nbsp;icon-heart
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-cloud" /> &nbsp;icon-cloud
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-upload" />{" "}
                                &nbsp;icon-upload
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-download" />{" "}
                                &nbsp;icon-download
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-target" />{" "}
                                &nbsp;icon-target
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-hazardous" />{" "}
                                &nbsp;icon-hazardous
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-piechart" />{" "}
                                &nbsp;icon-piechart
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-speedometer" />{" "}
                                &nbsp;icon-speedometer
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-global" />{" "}
                                &nbsp;icon-global
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-compass" />{" "}
                                &nbsp;icon-compass
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-lifesaver" />{" "}
                                &nbsp;icon-lifesaver
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-clock" /> &nbsp;icon-clock
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-aperture" />{" "}
                                &nbsp;icon-aperture
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-quote" /> &nbsp;icon-quote
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-scope" /> &nbsp;icon-scope
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-alarmclock" />{" "}
                                &nbsp;icon-alarmclock
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-refresh" />{" "}
                                &nbsp;icon-refresh
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-happy" /> &nbsp;icon-happy
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-sad" /> &nbsp;icon-sad
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-facebook" />{" "}
                                &nbsp;icon-facebook
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-twitter" />{" "}
                                &nbsp;icon-twitter
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-googleplus" />{" "}
                                &nbsp;icon-googleplus
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-rss" /> &nbsp;icon-rss
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-tumblr" />{" "}
                                &nbsp;icon-tumblr
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-linkedin" />{" "}
                                &nbsp;icon-linkedin
                            </div>
                            <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                                <span aria-hidden="true" className="icon-dribbble" />{" "}
                                &nbsp;icon-dribbble
                            </div>
                        </div>
                    </div>
                </section>
                {/* section close */}
            </div>
            {/* content close */}
            <a href="#" id="back-to-top" />
            <Footer/>
        </>

    )
}

export default IconsEtline
import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function GalleryPopup() {
    return (
        <>
            <Header />
                {/* content begin */}
                <div className="no-bottom no-top" id="content">
                    <div id="top" />
                    {/* section begin */}
                    <section id="subheader" className="jarallax text-light">
                        <img
                            src="images/background/subheader.jpg"
                            className="jarallax-img"
                            alt=""
                        />
                        <div className="center-y relative text-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <h1>Gallery</h1>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* section close */}
                    <section aria-label="section">
                        <div className="container">
                            <div id="gallery" className="row">
                                <div className="col-md-4 mb30 item">
                                    <div className="de-image-hover rounded">
                                        <a href="images/gallery/1.jpg" className="image-popup">
                                            <span className="dih-title-wrap">
                                                <span className="dih-title">Gallery Title</span>
                                            </span>
                                            <span className="dih-overlay" />
                                            <img
                                                src="images/gallery/1.jpg"
                                                className="lazy img-fluid"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 mb30 item">
                                    <div className="de-image-hover rounded">
                                        <a href="images/gallery/2.jpg" className="image-popup">
                                            <span className="dih-title-wrap">
                                                <span className="dih-title">Gallery Title</span>
                                            </span>
                                            <span className="dih-overlay" />
                                            <img
                                                src="images/gallery/2.jpg"
                                                className="lazy img-fluid"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 mb30 item">
                                    <div className="de-image-hover rounded">
                                        <a href="images/gallery/3.jpg" className="image-popup">
                                            <span className="dih-title-wrap">
                                                <span className="dih-title">Gallery Title</span>
                                            </span>
                                            <span className="dih-overlay" />
                                            <img
                                                src="images/gallery/3.jpg"
                                                className="lazy img-fluid"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 mb30 item">
                                    <div className="de-image-hover rounded">
                                        <a href="images/gallery/4.jpg" className="image-popup">
                                            <span className="dih-title-wrap">
                                                <span className="dih-title">Gallery Title</span>
                                            </span>
                                            <span className="dih-overlay" />
                                            <img
                                                src="images/gallery/4.jpg"
                                                className="lazy img-fluid"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 mb30 item">
                                    <div className="de-image-hover rounded">
                                        <a href="images/gallery/5.jpg" className="image-popup">
                                            <span className="dih-title-wrap">
                                                <span className="dih-title">Gallery Title</span>
                                            </span>
                                            <span className="dih-overlay" />
                                            <img
                                                src="images/gallery/5.jpg"
                                                className="lazy img-fluid"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 mb30 item">
                                    <div className="de-image-hover rounded">
                                        <a href="images/gallery/6.jpg" className="image-popup">
                                            <span className="dih-title-wrap">
                                                <span className="dih-title">Gallery Title</span>
                                            </span>
                                            <span className="dih-overlay" />
                                            <img
                                                src="images/gallery/6.jpg"
                                                className="lazy img-fluid"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/* content close */}
                <a href="#" id="back-to-top" />


                <Footer />
            </>

            )
}

            export default GalleryPopup